import { useState, useEffect } from "react";
import useFormatter from "../../../hooks/useFormatter";
import {
    Select as CustomSelect,
    ChartCard,
  } from "../../../components";

export default function AverageStatsChart  ({ categories, activeCategory, dateRange }) {
    const { formatDateToMMDD, formatDate, addCommas } = useFormatter();
    const [categorySum, setCategorySum] = useState([]);
    const [percentages, setPercentages] = useState({
      cost: 0,
      clicks: 0,
      impressions: 0,
      conversions: 0,
      volumes: 0,
    });
  
    // const calculatePercentageDifference = (data, range) => {
    //     const today = new Date();
    //     const currentStartDate = new Date(today);
    //     const previousStartDate = new Date(today);
  
    //     // Set date ranges based on the selected range (7 or 30 days)
    //     currentStartDate.setDate(today.getDate() - range);
    //     previousStartDate.setDate(currentStartDate.getDate() - range);
    //     console.log(today , ' 1')
    //     console.log(previousStartDate, ' 2' )
    //     console.log(range, ' 3' )
  
    //     // Initialize sums for the current and previous periods
    //     const currentPeriod = { clicks: 0, impressions: 0, conversions: 0, volumes: 0 };
    //     const previousPeriod = { clicks: 0, impressions: 0, conversions: 0, volumes: 0 };
  
    //     // Separate the data into current and previous periods based on the range
    //     data.forEach((item) => {
    //       const itemDate = new Date(item.Date);
  
    //       if (itemDate >= currentStartDate && itemDate <= today) {
    //         // Current period
    //         currentPeriod.impressions += parseInt(item.Impressions);
    //         currentPeriod.clicks += parseInt(item.Clicks);
    //         currentPeriod.conversions += parseInt(item.Conversions);
    //         currentPeriod.volumes += parseFloat(item["Conversion Value"].replace(/[$,]/g, ""));
    //       } else if (itemDate >= previousStartDate && itemDate < currentStartDate) {
    //         // Previous period
    //         previousPeriod.impressions += parseInt(item.Impressions);
    //         previousPeriod.clicks += parseInt(item.Clicks);
    //         previousPeriod.conversions += parseInt(item.Conversions);
    //         previousPeriod.volumes += parseFloat(item["Conversion Value"].replace(/[$,]/g, ""));
    //       }
    //     });
    //     // Calculate percentage difference
    //     const percentageDifference = (current, previous) => {
    //       if (previous === 0) return current === 0 ? 0 : 100; // Avoid division by zero
    //       return ((current - previous) / previous) * 100;
    //     };
    //     return {
    //       impressions: percentageDifference(currentPeriod.impressions, previousPeriod.impressions),
    //       clicks: percentageDifference(currentPeriod.clicks, previousPeriod.clicks),
    //       conversions: percentageDifference(currentPeriod.conversions, previousPeriod.conversions),
    //       volumes: percentageDifference(currentPeriod.volumes, previousPeriod.volumes),
    //     };
    //   };
  
    const calculatePercentageDifference = (data, range) => {
      const today = new Date();
  
      const currentStartDate = new Date(today);
      currentStartDate.setDate(today.getDate() - range);
  
      const previousStartDate = new Date(currentStartDate);
      previousStartDate.setDate(currentStartDate.getDate() - range);
  
      const currentPeriod = {
        cost: 0,
        clicks: 0,
        impressions: 0,
        conversions: 0,
        volumes: 0,
      };
      const previousPeriod = {
        cost: 0,
        clicks: 0,
        impressions: 0,
        conversions: 0,
        volumes: 0,
      };
  
      data.forEach((item) => {
        const itemDate = new Date(item.Date);
  
        if (itemDate >= currentStartDate && itemDate <= today) {
          currentPeriod.cost += parseFloat(
            item["Cost per Result"].replace(/[$,]/g, "")
          );
          currentPeriod.impressions += parseInt(item.Impressions);
          currentPeriod.clicks += parseInt(item.Clicks);
          currentPeriod.conversions += parseInt(item.Conversions);
          currentPeriod.volumes += parseFloat(
            item["Conversion Value"].replace(/[$,]/g, "")
          );
        } else if (itemDate >= previousStartDate && itemDate < currentStartDate) {
          previousPeriod.cost += parseFloat(
            item["Cost per Result"].replace(/[$,]/g, "")
          );
          previousPeriod.impressions += parseInt(item.Impressions);
          previousPeriod.clicks += parseInt(item.Clicks);
          previousPeriod.conversions += parseInt(item.Conversions);
          previousPeriod.volumes += parseFloat(
            item["Conversion Value"].replace(/[$,]/g, "")
          );
        }
      });
  
      const percentageDifference = (current, previous) => {
        if (previous === 0) return current === 0 ? 0 : 100;
        return ((current - previous) / previous) * 100;
      };
  
      return {
        cost: percentageDifference(currentPeriod.cost, previousPeriod.cost),
        impressions: percentageDifference(
          currentPeriod.impressions,
          previousPeriod.impressions
        ),
        clicks: percentageDifference(currentPeriod.clicks, previousPeriod.clicks),
        conversions: percentageDifference(
          currentPeriod.conversions,
          previousPeriod.conversions
        ),
        volumes: percentageDifference(
          currentPeriod.volumes,
          previousPeriod.volumes
        ),
      };
    };
  
    // const setChart = ({ category, range }) => {
    //     const totalData = category.TotalData;
    //     let startDate = null;
    //     let endDate = null;
    //     let clicks = 0;
    //     let impressions = 0;
    //     let conversions = 0;
    //     let volumes = 0;
  
    //     // Get the date range for the last 7 days
    //     const today = new Date();
    //     const sevenDaysAgo = new Date(today);
    //     sevenDaysAgo.setDate(today.getDate() - range);
  
    //     totalData.data.forEach((item) => {
    //         const itemDate = new Date(item.Date);
  
    //         if (itemDate >= sevenDaysAgo && itemDate <= today) {
    //             if (!startDate || itemDate < startDate) startDate = itemDate;
    //             if (!endDate || itemDate > endDate) endDate = itemDate;
  
    //             impressions += parseInt(item.Impressions);
    //             clicks += parseInt(item.Clicks);
    //             conversions += parseInt(item.Conversions);
    //             volumes += parseFloat(item["Conversion Value"].replace(/[$,]/g, ""));
    //         }
    //     });
  
    //     // Format startDate and endDate for display
    //     const formattedStartDate = startDate ? formatDateToMMDD(startDate) : "";
    //     const formattedEndDate = endDate ? formatDateToMMDD(endDate) : "";
  
    //     const sum = [
    //         {
    //             progress: impressions,
    //             scaledProgress: Math.log(impressions || 1), // Prevent log(0) by using || 1
    //             formattedProgress: addCommas(impressions),
    //             yName: "impressions",
    //             xName: range !== 1000 ? formattedStartDate : "",
    //         },
    //         {
    //             progress: clicks,
    //             scaledProgress: Math.log(clicks || 1),
    //             formattedProgress: addCommas(clicks),
    //             yName: "clicks",
    //             xName: "",
    //         },
    //         {
    //             progress: conversions,
    //             scaledProgress: Math.log(conversions || 1),
    //             formattedProgress: addCommas(conversions),
    //             yName: "conversions",
    //             xName: "",
    //         },
    //         {
    //             progress: volumes,
    //             scaledProgress: Math.log(volumes || 1),
    //             formattedProgress: addCommas(volumes),
    //             yName: "volumes",
    //             xName: range !== 1000 ? formattedEndDate : "",
    //         },
    //     ];
  
    //     setCategorySum(sum);
    //     const percentageDiff = calculatePercentageDifference(totalData.data, parseInt(range));
    //     // console.log('Percentage Difference:', percentageDiff);
    //     setPercentages(percentageDiff);
    // };
  
    const setChart = ({ category, range }) => {
      const totalData = category.TotalData;
      let startDate = null;
      let endDate = null;
  
      let cost = 0;
      let clicks = 0;
      let impressions = 0;
      let conversions = 0;
      let volumes = 0;
  
      // Get the date range for the last 7 days, excluding the current day
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to start of the current day
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - range);
  
      totalData.data.forEach((item) => {
        const itemDate = new Date(item.Date);
        itemDate.setHours(0, 0, 0, 0); // Set to start of the item date
  
        // Filter data within the last 7 days, excluding the current day
        if (itemDate >= sevenDaysAgo && itemDate < today) {
          if (!startDate || itemDate < startDate) startDate = itemDate;
          if (!endDate || itemDate > endDate) endDate = itemDate;
  
          cost += parseFloat(item["Cost per Result"].replace(/[$,]/g, ""));
          impressions += parseInt(item.Impressions);
          clicks += parseInt(item.Clicks);
          conversions += parseInt(item.Conversions);
          volumes += parseFloat(item["Conversion Value"].replace(/[$,]/g, ""));
        }
      });
      
      const formattedStartDate = startDate ? formatDateToMMDD(startDate) : "";
      const formattedEndDate = endDate ? formatDateToMMDD(endDate) : "";
  

      const sum = [
        {
          progress: (cost / range).toFixed(2),
          scaledProgress: Math.log(cost || 1),
          formattedProgress: addCommas(cost),
          yName: "cost",
          xName: range !== 1000 ? formattedEndDate : "",
        },
        // {
        //     progress: impressions,
        //     scaledProgress: Math.log(impressions || 1), // Prevent log(0) by using || 1
        //     formattedProgress: addCommas(impressions),
        //     yName: "impressions",
        //     xName: range !== 1000 ? formattedStartDate : "",
        // },
        {
          progress: clicks,
          scaledProgress: Math.log(clicks || 1),
          formattedProgress: addCommas(clicks),
          yName: "clicks",
          xName: "",
        },
        {
          progress: conversions,
          scaledProgress: Math.log(conversions || 1),
          formattedProgress: addCommas(conversions),
          yName: "conversions",
          xName: "",
        },
        {
          progress: volumes,
          scaledProgress: Math.log(volumes || 1),
          formattedProgress: addCommas(volumes),
          yName: "volumes",
          xName: range !== 1000 ? formattedEndDate : "",
        },
      ];
  
      setCategorySum(sum);
      const percentageDiff = calculatePercentageDifference(
        totalData.data,
        parseInt(range)
      );
      // console.log('Percentage Difference:', percentageDiff);
      setPercentages(percentageDiff);
    };
  
    useEffect(() => {
      if (!Object.keys(categories).length) return;
      const category = categories[activeCategory];
      const range = dateRange.find((item) => item.isSelected).value;
  
      setChart({ category, range });
    }, [activeCategory, categories, dateRange]);
  
    const [percentageText, setPercentageText] = useState("");
    useEffect(() => {
      const value = parseInt(dateRange.find((item) => item.isSelected).value);
      switch (value) {
        case 7:
          setPercentageText("than last week");
          break;
        case 30:
          setPercentageText("than last month");
          break;
        case 90:
          setPercentageText("than last 3 month");
          break;
        case 1000:
          setPercentageText("all time");
          break;
      }
    }, [dateRange]);
    return (
      <div className="average-stats">
        <div className="title-container">
          <h3
            className="average-stats-title"
          >
            Ad Network Stats 
          </h3>
      
        </div>
        <br />
        <div className="card-group" >
          {categorySum.map((item, index) => {
            return (
              <ChartCard
                key={index}
                title={item.yName == "cost" ? "Avg. Conversion Cost" : item.yName}
                value={
                  (item.yName == "volumes" || item.yName == "cost" ? "$" : "") +
                  addCommas(item.progress)
                }
                color="success"
                percentage={percentages[item.yName].toFixed(2)}
                text={percentageText}
              />
            );
          })}
        </div>
      </div>
    );
  };
  
