// import React, { PureComponent, useEffect } from "react";
// import {
//     LineChart,
//     Line,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     ResponsiveContainer,
//     Scatter,
//     ReferenceLine,
// } from "recharts";
// import { Loading } from "../../../";
// import CustomTooltip from "../custom-tooltip/CustomTooltip";

// const isSmallScreen = window.innerWidth < 768;
// const fontSize = isSmallScreen ? 8 : 12;

// export default class Example extends PureComponent {
//     static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";

//     render() {
//         const {
//             data = [],
//             defaultData = [],
//             isLoading = false,
//             uvColor = "",
//             amtColor = "",
//             lines = [],
//         } = this.props;
//         console.log(data)
//         return (
//             <div className="position">
//                 <div className="position-inner">
//                     <ResponsiveContainer width="100%" height="100%">
//                         <LineChart
//                             width={500}
//                             height={300}
//                             data={data.length && data.length > 1 && !isLoading ? data : defaultData}
//                             // data={data.length && !isLoading ? data : defaultData}
//                             // data={defaultData}
//                             margin={{
//                                 top: 30,
//                                 right: 0,
//                                 left: 0,
//                                 bottom: 30,
//                             }}
//                         >
//                             <CartesianGrid strokeDasharray="" vertical={false} />
//                             <XAxis
//                                 dataKey="name"
//                                 axisLine={false}
//                                 tickLine={false}
//                                 dy={15}
//                                 tick={{ fontSize: fontSize }}
//                             />
//                             {/* <YAxis
//                                 axisLine={false}
//                                 tick={{ fontSize: fontSize }}
//                                 tickLine={false}
//                                 dx={-5}
//                                 // ticks={[0, 500, 1000, 2500, 5000, 7500, 10000]}
//                             /> */}
//                             <Tooltip content={<CustomTooltip active={true} />}/>

//                             {!lines.length
//                                 ? ""
//                                 : lines.map((item, index) => {
//                                       return (
//                                           <Line
//                                               key={index}
//                                               type="linear"
//                                               strokeWidth="2"
//                                               dataKey={item.dataName}
//                                               stroke={item.color}
//                                               dot={false}
//                                           />
//                                       );
//                                   })}
//                             <Line type="linear" strokeWidth="3" dataKey="empty" stroke={"#808080"} dot={false} />
//                             {/* <ReferenceLine y={500} stroke="#ccc" />
//                             <ReferenceLine y={1000} stroke="#ccc" /> */}
//                         </LineChart>
//                     </ResponsiveContainer>
//                 </div>
//             </div>
//         );
//     }
// }

import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? -20 : -10;

export default function Example({
  data = [],
  defaultData = [],
  isLoading = false,
  lines = [],
  className = "",
  isTooltipFormatted = false,
  isHover = false
}) {
  // static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";
  const { formatNumber, formatCurrency, addCommas } = useFormatter();

  // const CustomTooltip = ({ payload, label }) => {
  //     if (!payload || payload.length === 0) return null;

  //     // Retrieve the original values from the payload
  //     const originalData = payload[0].payload;
  //     const impressions = originalData.impressions || "";
  //     const amount_spent = originalData.amount_spent || "";
  //     const clicks = originalData.clicks || "";
  //     const CPM = originalData.CPM || "";
  //     const CPC = originalData.CPC || "";
  //     console.log(originalData, ' data')
  //     return (
  //         <div className="chart-tooltip">
  //             <strong className="label">{`${label}`}</strong>
  //             {impressions && <p className="label">{`Impressions: ${formatNumber(impressions)}`}</p>}
  //             {amount_spent && <p className="label">{`Ad Spend: ${formatCurrency(amount_spent, 2)}`}</p>}
  //             {clicks && <p className="label">{`Clicks: ${formatNumber(clicks)}`}</p>}

  //             {CPM && <p className="label">{`CPM: ${formatCurrency(CPM, 2)}`}</p>}
  //             {CPC && <p className="label">{`CPC: ${formatCurrency(CPC, 2)}`}</p>}
  //         </div>
  //     );
  // };

  const activeCount = lines.filter((item) => item.isActive).length;

  return (
    <div className={`position ${className}`}>
      <div className="position-inner">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data.length && !isLoading ? data : defaultData}
            margin={{
              top: 30,
              right: 0,
              left: leftMargin,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              dy={15}
              tick={{ fontSize: fontSize }}
              // interval="auto"
            />
            {activeCount === 1 && (
              <YAxis
                axisLine={false}
                tick={{ fontSize: fontSize }}
                tickLine={false}
                tickFormatter={(tick) => addCommas(tick) || "0"}
                dx={-15}
              />
            )}
            
            <Tooltip
              formatter={(value, name, props) => {                
                const originalValue = props.payload;                
                if (isTooltipFormatted) {
                  if (name === "CPM")
                    return formatCurrency(originalValue.AllCPM, 2);
                  if (name === "CPC")
                    return formatCurrency(originalValue.AllCPC, 2);
                  // if (name === "CPM") return "-";
                  // if (name === "CPC") return "-";
                }
                if (name === "impressions")
                  return formatNumber(originalValue.impressions + 1);
                if (name === "ad spend")
                  return formatCurrency(originalValue.amount_spent, 2);
                if (name === "clicks")
                  return formatNumber(originalValue.clicks);
                if (name === "CPM") return formatCurrency(originalValue.CPM, 2);
                if (name === "CPC") return formatCurrency(originalValue.CPC, 2);

                if (name === "unknown") return "0";
                return formatNumber(value);
              }}
            />
            {/* <Tooltip
                            formatter={(value, name, props) => {
                                // const find = lines.find(item => item.displayName == name)
                                // if(find && !find.isActive) return
                                console.log(props.payload)
                                const originalValue = props.payload
                                if (name === "impressions") return formatNumber(value);
                                if (name === "ad spend") return formatCurrency(value, 2);
                                if (name === "clicks") return formatNumber(value);
                                if (name === "CPC") return formatCurrency(value, 2);
                                if (name === "CPM") return formatCurrency(value, 2);

                                // if (name === "Conversions") return formatNumber(value);
                                // if (name === "Engaged Sessions") return formatNumber(value);
                                // if (name === "Engagement Rate") return formatNumber(value);
                                // if (name === "Event Count") return formatNumber(value);
                                // if (name === "New Users") return formatNumber(value);
                                // if (name === "Total Revenue") return formatNumber(value);

                                
                                if (name === "unknown") return "0";
                                return formatNumber(value);
                            }}
                        /> */}
            {/* <Tooltip content={<CustomTooltip />} /> */}
            {!lines.length
              ? ""
              : lines.map((line, index) => {
                  if (!line.isActive) return;
                  return (
                    <Line
                      key={index}
                      //   dataKey={line.propertyName}
                      dataKey={line.normalizeName}
                      name={line.displayName}
                      stackId={data.length > 10 ? "a" : undefined}
                      type="monotone"
                      strokeWidth="3"
                      // fill={line.color}
                      stroke={line.color}
                      dot={isHover}  
                    />
                  );
                })}
            <Line
              type="monotone"
              dataKey="empty"
              name="unknown"
              stackId="a"
              strokeWidth="1"
              stroke={"#808080"}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
