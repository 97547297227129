import React, { useEffect, useState } from 'react';
import localData from "../../localData";

const MultiSelectInput = ({
  label = "",
  labelInfo= "", 
  name="",
  callBack = () => {},
  setItems = () => console.log(`Error: "setItems function required"`),
  items,
  disabled
}) => {
  const [tags, setTags] = useState(items ? items : []);  
  const [inputValue, setInputValue] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const maxTags = 5;
  const { close } = localData.svgs;

  useEffect(() => {
    setTags(items ? items : [])
    }, [items])

  const addressRegex = /^0x[a-fA-F0-9]+$/;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',' && inputValue.trim() !== '') {
      const newTag = inputValue.trim();

      if (!addressRegex.test(newTag)) {
        setValidationMessage("Invalid Contract Address format.");
        return;
      }

      if (tags.includes(newTag)) {
        setValidationMessage("This address has already been added.");
        return;
      }

      if (tags.length < maxTags) {
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        setItems(updatedTags);
        callBack(updatedTags);
        setInputValue('');
        setValidationMessage('');
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (validationMessage) {
      setValidationMessage('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter(tag => tag !== tagToRemove);
    setTags(updatedTags);
    setItems(updatedTags);
    callBack(updatedTags);
  };

  return (
    <div>
        {label &&  <label
            className="multi-select-label"
            data-tooltip-id={`tooltip-campaign-${name}`}
            data-tooltip-content={labelInfo}
        >
            {label}
        </label>}
      
    <div className="multi-select-container">
      <div className="tags-container">
        {(tags || []).map(tag => (
          <div key={tag} className="tag">
            <span className="remove-tag" onClick={() => handleRemoveTag(tag)}>{close}</span>
            <p className="ellipsis">{tag}</p>
          </div>
        ))}
        {tags.length < maxTags && (
          <div className="input-conatiner">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type and press enter"
              className="tag-input"
              disabled={disabled}
            />
            {validationMessage && (
              <p style={{ color: 'red', fontSize: '12px' }}>
                {validationMessage}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default MultiSelectInput;
