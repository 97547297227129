import React, { useState, useEffect } from "react";
import useUtil from "../../../../../hooks/useUtil";
import { useGlobalContext } from "../../../../../context";
import { CopyTextButton, Button, Modal, Tabs } from "../../../../../components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import localData from "../../../../../localData";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import TrackingHelp from "../../../tracking-help/TrackingHelp";
import Joyride, { STATUS } from "react-joyride";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const {
  edit,
  question,
  password,
  support,
  userShield,
  ellipsisVeritcal,
  arrowRight,
  penV2,
  textPreloader,
  BloomingImage,
  info,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function InstallPixelTab({
  setActiveTab,
  activeTab,
  setIsCreateConversionPixel,
}) {
  const {
    CreatePixelTagRequest,
    CreateConversionPixelRequest,
    conversionOptions,
    setConversionOptions,
    trackingState,
    requestedData,
    requestedData: {
      userProfileData: { currentOrganization },
    },
  } = useGlobalContext();
  const { isEditingMode } = trackingState;
  const [script, setScript] = useState("");

  useEffect(() => {
    if (requestedData?.userProfileData?.currentOrganization?.id) {
      CreatePixelTagRequest();
    }
  }, [requestedData]);

  const [showInstallPixelModal, setShowInstallPixelModal] = useState("none");

  const [instalPixelActiveTab, setInstalPixelActiveTab] = useState("");

  const [pixelTab, setPixelTab] = useState(instalPixelActiveTab);
  const [showTrackingHelpModal, setShowTrackingHelpModal] = useState("none");

  return (
    <Accordion
      defaultExpanded
      style={{
        border: "2px solid rgba(219, 222, 225,1)",
        borderRadius: "14px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{ fontWeight: "bold" }}
      >
        <span className="green-title-accordion">PageView</span> Pixel
      </AccordionSummary>
      <AccordionDetails>
        <div className="install-pixel-tab">
          <InstallPixelJoyRide activeTab={activeTab} />
          {/* <h4 className="install-pixel-tab-title " id="install-pixel-tutorial-1">
                Install Pixel
            </h4> */}
          <Tabs
            contentId="install-pixel-tutorial-2"
            // hideTabs={true}
            tabsProps={{}}
            tabs={installPixelCodeTabs}
            title="fsdfsd"
            callback={setInstalPixelActiveTab}
            activeTabInstalPixel={pixelTab}
            // indicatorIcon={question}
            // className="install-pixel-code-tabs"
          />
          <br />
          

          
          <div className="install-pixel-tab-footer">
            <div className="d-flex" style={{justifyContent: "space-between", flexWrap: "wrap", gap: "10px"}}>
            <div className="btn-group" style={{ justifyContent: "center" }}>
              <div className="tracking-help">
              <div
                className="help"
                data-tooltip-id="tooltip-tracking"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    <div className="instructions">
                        <div className="instructions-header">
                            Step-by-step instructions for Google Tag Manager
                        </div>
                        <div className="instructions-body">
                            <ul>
                                <li data-number="1">
                                    Create a new tag and select 'Custom HTML' as the tag type.
                                </li>
                                <li data-number="2">
                                    Paste the code snippet in the HTML field, set the trigger to 'All Pages'
                                    and save the tag.
                                </li>
                                <li data-number="3">Submit the changes and publish the container.</li>
                            </ul>
                        </div>
                    </div>
                )}
            >
                Show step-by-step instructions <div className="help-icon">{info}</div>
                <Tooltip id="tooltip-tracking" className="custom-tooltip tracking-instructions-tooltip" />
            </div>
            </div>
            </div>
            <div>
            <Modal
                Child={HelpContent}
                className="tracking-help-modal modal-dialog-centered"
                display={showTrackingHelpModal}
                setDisplay={setShowTrackingHelpModal}
                Toggler={() => (
                    <Button
                        className="tracking-help-btn"
                        name="Integration docs"
                        // startIcon={info}
                        style={{marginRight: "5px"}}
                        size="md"
                        color="pale-dark"
                        variant="outlined"
                        onClick={() => setShowTrackingHelpModal("block")}
                    />
                )}
            ></Modal>
            {!isEditingMode && (
                <Modal
                  Child={InstallPixelModal}
                  className="install-pixel-modal tracking-modal modal-dialog-centered"
                  display={showInstallPixelModal}
                  setDisplay={setShowInstallPixelModal}
                  restProps={{ setActiveTab, setIsCreateConversionPixel }}
                  Toggler={() => (
                    <Button
                      id="install-pixel-tutorial-3"
                      className="next-step-btn"
                      name="Confirm"
                      // endIcon={arrowRight}
                      size="md"
                      variant="contained"
                      color="primary"
                      style={{ background: "#2aab7e" }}
                      onClick={() => setShowInstallPixelModal("block")}
                    />
                  )}
                ></Modal>
              )}
            </div>
            
       
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export const InstallPixelJoyRide = ({ activeTab }) => {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          Follow on-screen instructions to place the script on your website.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Placing Tracking Script",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          Copy paste this script in the {"<head>"} tag of your website or in a
          custom html tag on Tag Manager.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#install-pixel-tutorial-2",
      title: "Copying Tracking Script",
    },
    {
      content: <h2 className="joyride-subtitle">Click here to continue.</h2>,
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#install-pixel-tutorial-3",
      title: "Continuing Setup",
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "installPixelTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("installPixelTutorial"))
      return;
    if (activeTab == 0) {
      setTimeout(() => {
        setSteps((prev) => ({ ...prev, run: true }));
      }, 500);
    }
  }, [activeTab]);

  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

const InstallPixelModal = ({
  setIsOpen,
  setActiveTab,
  setIsCreateConversionPixel,
}) => {
  const { setIsConversionModalOpen } = useGlobalContext();
  const { requestedData } = useGlobalContext();
  const { close } = localData.svgs;
  const closeModal = () => {
    setIsOpen(false);
    setIsConversionModalOpen(true);
    setTimeout(() => {
      const element = document.getElementById("tagName");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <InstallPixelModalJoyRide />
      </div>

      <div className="modal-header">
        <h2 className="modal-title">Confirmation</h2>

        <Button
          className="btn-close"
          variant="circle"
          color="dark"
          size="sm"
          icon={close}
          onClick={closeModal}
        />
      </div>
      <div className="modal-body">
        {/* need to confirm
                <br />
                <br /> */}
        <TrackingHelp />
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <Button
            className="cancel-btn"
            name="Cancel"
            size="sm"
            variant="contained"
            color="light"
            onClick={closeModal}
          />
          <Button
            id="install-pixel-modal-tutorial-2"
            className="next-step-btn"
            name="Continue"
            size="sm"
            variant="contained"
            color="pale-dark"
            onClick={(e) => {
              closeModal();
              setTimeout(() => {
                setIsCreateConversionPixel(true);
              }, 500);
            }}
          />
        </div>
      </div>
    </>
  );
};

const InstallPixelModalJoyRide = () => {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          Review the instructions before proceeding further.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Integration",
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Click here to confirm understanding of the instructions.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#install-pixel-modal-tutorial-2",
      title: "Confirm Understanding",
    },
  ];
  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (
      !guideTutorials ||
      !guideTutorials.includes("installPixelModalTutorial")
    )
      return;
    setTimeout(() => {
      setSteps((prev) => ({ ...prev, run: true }));
    }, 500);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "installPixelModalTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export const GoogleTagManagerContent = () => {
  const { conversionOptions } = useGlobalContext();
  const gmtLink = `<script>
       var script = document.createElement('script');
       script.defer = true;
       script.src = 'https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js';
       script.dataset.siteId = "1264";
       document.getElementsByTagName('head')[0].appendChild(script);
    </script>`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className="code">
          <div className="row">
            <div className="label">
              <img src={gtm} alt="" />
              Google Tag Manager (GTM)
            </div>
            <CopyTextButton
              text={conversionOptions.pixelScriptTag.value}
              size="medium"
            />
          </div>
          <div className="script-wrapper" style={{ fontSize: "12px" }}>
            {conversionOptions.pixelScriptTag.isLoading ? (
              "Loading..."
            ) : (
              <SyntaxHighlighter
                language="html"
                style={coy}
                showLineNumbers={true}
              >
                {conversionOptions.pixelScriptTag.value}
              </SyntaxHighlighter>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const NextJSContent = () => {
  const { conversionOptions } = useGlobalContext();
  const nextjsLink = `<Script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    />`;

  return (
    <div className="code">
      <div className="row">
        <div className="label">
          <img src={nextjs} alt="" />
          _app.txt
        </div>
        <CopyTextButton
          text={conversionOptions.pixelScriptTag.value}
          size="medium"
        />
      </div>
      <div className="script-wrapper" style={{ fontSize: "12px" }}>
        {conversionOptions.pixelScriptTag.isLoading ? (
          "Loading..."
        ) : (
          <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
            {conversionOptions.pixelScriptTag.value}
          </SyntaxHighlighter>
        )}
      </div>
    </div>
  );
};

const HTMLContent = () => {
  const { conversionOptions } = useGlobalContext();
  const htmlLink = `<script
        src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
        integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
        crossOrigin="anonymous"
        async="true"
        strategy="lazyOnload"
        data-site-id="1264"
        data-chain-tracking-enabled="true"
    ></script>`;

  return (
    <div className="code">
      <div className="row">
        <div className="label">
          <img src={html} alt="" />
          index.html
        </div>
        <CopyTextButton
          text={conversionOptions.pixelScriptTag.value}
          size="medium"
        />
      </div>
      <div className="script-wrapper" style={{ fontSize: "12px" }}>
        {conversionOptions.pixelScriptTag.isLoading ? (
          "Loading..."
        ) : (
          <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
            {conversionOptions.pixelScriptTag.value}
          </SyntaxHighlighter>
        )}
      </div>
    </div>
  );
};

const NuxtContent = () => {
  const { conversionOptions } = useGlobalContext();
  const htmlLink = `<script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async="true"
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    ></script>`;

  return (
    <div className="code">
      <div className="row">
        <div className="label">
          <img src={nuxt} alt="" />
          app.vue
        </div>
        <CopyTextButton
          text={conversionOptions.pixelScriptTag.value}
          size="medium"
        />
      </div>
      <div className="script-wrapper" style={{ fontSize: "12px" }}>
        {conversionOptions.pixelScriptTag.isLoading ? (
          "Loading..."
        ) : (
          <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
            {conversionOptions.pixelScriptTag.value}
          </SyntaxHighlighter>
        )}
      </div>
    </div>
  );
};

const WordpressContent = () => {
  const { conversionOptions } = useGlobalContext();
  const htmlLink = `<script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async="true"
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    ></script>`;

  return (
    <div className="code">
      <div className="row">
        <div className="label">
          <img src={wordpress} alt="" />
          functions.php
        </div>
        <CopyTextButton
          text={conversionOptions.pixelScriptTag.value}
          size="medium"
        />
      </div>
      <div className="script-wrapper" style={{ fontSize: "12px" }}>
        {conversionOptions.pixelScriptTag.isLoading ? (
          "Loading..."
        ) : (
          <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
            {conversionOptions.pixelScriptTag.value}
          </SyntaxHighlighter>
        )}
      </div>
    </div>
  );
};

export const PixelContent = () => {
  const {
    CreatePixelTagRequest,
    CreateConversionPixelRequest,
    conversionOptions,
    setConversionOptions,
    requestedData: {
      userProfileData: { currentOrganization },
    },
  } = useGlobalContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className="code">
          <div className="row">
            <div className="label">
              <img src={pixel} alt="" />
              Tracking Pixel
            </div>
            <CopyTextButton
              text={conversionOptions.pixelScriptTag.value}
              size="medium"
            />
          </div>
          <div className="script-wrapper" style={{ fontSize: "12px" }}>
            {conversionOptions.pixelScriptTag.isLoading ? (
              "Loading..."
            ) : (
              <SyntaxHighlighter
                language="html"
                style={coy}
                showLineNumbers={true}
              >
                {conversionOptions.pixelScriptTag.value}
              </SyntaxHighlighter>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const installPixelCodeTabs = [
  {
    title: "Google Tag Manager",
    startImageIcon: gtm,
    content: <GoogleTagManagerContent />,
    id: "tracking-1",
  },
  // {
  //     title: "Next.js",
  //     startImageIcon: nextjs,
  //     content: <NextJSContent />,
  //     id: "tracking-2",
  // },
  // {
  //     title: "HTML",
  //     startImageIcon: html,
  //     content: <HTMLContent />,
  //     id: "tracking-3",
  // },
  // {
  //     title: "Nuxt",
  //     startImageIcon: nuxt,
  //     content: <NuxtContent />,
  //     id: "tracking-4",
  // },
  // {
  //     title: "Wordpress",
  //     startImageIcon: wordpress,
  //     content: <WordpressContent />,
  //     id: "tracking-5",
  // },
  {
    title: "Tracking Pixel",
    startImageIcon: pixel,
    content: <PixelContent />,
    id: "tracking-6",
  },
];

const HelpContent = ({ setIsOpen, setActiveTab }) => {
  const { close } = localData.svgs;
  const closeModal = () => {
      setIsOpen(false);
  };
  return (
      <>
          <div className="modal-header">
              <h2 className="modal-title">Documentation</h2>

              <Button
                  className="btn-close"
                  variant="circle"
                  color="dark"
                  size="sm"
                  icon={close}
                  onClick={closeModal}
              />
          </div>
          <div className="modal-body">
          <ul>
      <li data-number='1.'>Copy the Custom Script:</li>
      <li data-number='2.'>Visit the Blockchain-Ads website at app.blockchain-ads.com/data-sources/website.</li>
      <li data-number='3.'>
          In the table displaying your websites, locate the row corresponding to your website.
      </li>
      <li data-number='4.'>
          Click on the code icon next to your website row to copy the custom script specific to your website.
      </li>
      <li data-number='5.'>Add the Blockchain-Ads script to your Google Tag Manager project:</li>
      <li data-number='6.'>Visit tagmanager.google.com.</li>
      <li data-number='7.'>Select the project you want to add the Blockchain-Ads script to.</li>
      <li data-number='8.'>Click on Tags in the left sidebar.</li>
      <li data-number='9.'>Click on New.</li>
      <li data-number='10.'>Click on Tag Configuration.</li>
      <li data-number='11.'>Click on Custom HTML.</li>
      <li data-number='12.'>Paste the Blockchain-Ads script you copied in step 1 into the HTML field.</li>
      <li data-number='13.'>Click on Triggering.</li>
      <li data-number='14.'>Click on All Pages.</li>
  </ul>
  <br />
  <p>
      After pasting the script and selecting the All Pages trigger, your screen should look like this. You can now click on the Save button in the top-right corner. Please note that the "tagID" in the script is customized for your website and will be different from the one shown in the screenshot.
  </p>
  <br />
  <br />
  <ul>
      <li data-number='15.'>Click on Save.</li>
      <li data-number='16.'>Click on Submit.</li>
      <li data-number='17.'>Click on Publish.</li>
  </ul>
          </div>
          <div className="modal-footer">
              <div className="btn-group">
                  <Button
                      className="close-btn"
                      name="Close"
                      size="sm"
                      variant="contained"
                      color="light"
                      onClick={closeModal}
                  />
              </div>
          </div>
      </>
  );
};
