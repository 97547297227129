import React, { useEffect, useState } from "react";
import {
  Button,
  AreaChart,
} from "../../../components";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import useFormatter from "../../../hooks/useFormatter";


export default function AnalysisChart  ({ categories, activeCategory, dateRange }) {
    const { formatDateToMMDD, addCommas } = useFormatter();
    const [data, setData] = useState([]);
    const [properties, setProperties] = useState([
      { name: "cost", displayName: "Cost per Result", isSelected: true },
      { name: "impressions", displayName: "Impressions", isSelected: false },
      { name: "clicks", displayName: "Clicks", isSelected: false },
      { name: "conversions", displayName: "Conversions", isSelected: false },
      { name: "volumes", displayName: "Conversion Value", isSelected: false },
    ]);
  
    // const setChart = ({ category, range }) => {
    //     const totalData = category.TotalData;
    //     let startDate = null;
    //     let endDate = null;
    //     const sum = [];
  
    //     // Calculate the date range for the last 7 days
    //     const today = new Date();
    //     const sevenDaysAgo = new Date(today);
    //     sevenDaysAgo.setDate(today.getDate() - range);
  
    //     totalData.data.forEach((item) => {
    //         const itemDate = new Date(item.Date);
  
    //         // Filter data within the last 7 days
    //         if (itemDate >= sevenDaysAgo && itemDate <= today) {
    //             if (!startDate || itemDate < startDate) startDate = itemDate;
    //             if (!endDate || itemDate > endDate) endDate = itemDate;
  
    //             const activeProperty = properties.find((prop) => prop.isSelected);
  
    //             const obj = {
    //                 name: formatDateToMMDD(itemDate),
    //                 progress: parseInt(item[activeProperty.displayName].replace(/[$,]/g, "")),
    //                 ySecondaryName: activeProperty.name,
    //             };
    //             sum.push(obj);
    //         }
    //     });
  
    //     setData(sum);
    // };
  
    const setChart = ({ category, range }) => {
      const totalData = category.TotalData;
      let startDate = null;
      let endDate = null;
      const sum = [];
  
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - range);
  
      totalData.data.forEach((item) => {
        const itemDate = new Date(item.Date);
        itemDate.setHours(0, 0, 0, 0);
  
        if (itemDate >= sevenDaysAgo && itemDate < today) {
          if (!startDate || itemDate < startDate) startDate = itemDate;
          if (!endDate || itemDate > endDate) endDate = itemDate;
  
          const activeProperty = properties.find((prop) => prop.isSelected);
  
          const obj = {
            name: formatDateToMMDD(itemDate),
            progress: parseFloat(
              parseFloat(item[activeProperty.displayName].replace(/[$,]/g, ""))
                .toFixed(2)
            ),
            ySecondaryName: activeProperty.name,
          };

          sum.push(obj);
        }
      });  
      setData(sum);
    };
  
    useEffect(() => {
      if (!Object.keys(categories).length) return;
      const category = categories[activeCategory];
      const range = dateRange.find((item) => item.isSelected).value;
  
      setChart({ category, range });
    }, [activeCategory, categories, dateRange, properties]);

    const [selectedValue, setSelectedValue] = useState(
      properties.find((item) => item.isSelected).name
    );
  
    const handleChange = (e) => {
      const selected = e.target.value;
  
      const updatedProperties = properties.map((item) => ({
        ...item,
        isSelected: item.name === selected,
      }));
  
      setSelectedValue(selected);
      setProperties(updatedProperties);
    };
    
  
    return (
      <div className="analysis-chart ">
        <div className="header-container">
        <h3 className="analysis-chart-title">Trend Analysis</h3>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  {properties.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                    >
                      {item.name === "cost"
                        ? "Avg. Conversion Cost"
                        : item.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </div>
        
        <br />
        <div className="shadow">
          <div className="wrapper ">
            <div className="btn-group">

            </div>
          </div>
          <br />
          <br />
  
          <AreaChart
            // data={[
            //     {
            //         name: "Page A",
            //         uv: 4000,
            //         pv: 2400,
            //         amt: 2400,
            //     },
            //     {
            //         name: "Page B",
            //         uv: 3000,
            //         pv: 1398,
            //         amt: 2210,
            //     },
            //     {
            //         name: "Page C",
            //         uv: 2000,
            //         pv: 9800,
            //         amt: 2290,
            //     },
            //     {
            //         name: "Page D",
            //         uv: 2780,
            //         pv: 3908,
            //         amt: 2000,
            //     },
            //     {
            //         name: "Page E",
            //         uv: 1890,
            //         pv: 4800,
            //         amt: 2181,
            //     },
            //     {
            //         name: "Page F",
            //         uv: 2390,
            //         pv: 3800,
            //         amt: 2500,
            //     },
            //     {
            //         name: "Page G",
            //         uv: 3490,
            //         pv: 4300,
            //         amt: 2100,
            //     },
            // ]}
            data={data}
          />
        </div>
      </div>
    );
  };
  