import React, { useState, useEffect, useRef } from "react";
import {
    TableSkeleton,
    Select,
    Button,
    Popup,
    Pagination,
    Checkbox,
    Search,
    CopyTextButton,
} from "../../../../components/index.js";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";

import localData from "../../../../localData.js";
import { useGlobalContext } from "../../../../context.js";
import { useStateContext } from "../../../../stateContext.jsx";
import { auth } from "../../../../config/firebase.js";
import useFetch from "../../../../hooks/useFetch.js";
import useFormatter from "../../../../hooks/useFormatter.jsx";
import useBlockchainsSort from "../../../../hooks/useBlockchainsSort.jsx";
import useUtil from "../../../../hooks/useUtil.jsx";

export default function BlockchainsTable({ data, setActiveTab,  }) {
    const { requestedData, setRequestedData, isLoading, blockchainsState, isAdminTeamMember } =
        useGlobalContext();
    const {
        blockchainsPage,
        setBlockchainsPage,
        blockchainsPageSize,
        setBlockchainsPageSize,
        blockchainsTotalPages,
        setBlockchainsTotalPages,
        blockchainsItemsPerPage,
        setBlockchainsItemsPerPage,
        blockchains,
        setBlockchains,
        // setSelectedQuery
        activeNetwork
    } = blockchainsState;

    const { textPreloader } = localData.images;
    const { searchIcon } = localData.svgs;

    const handlePagination = (nextPage = 0) => {
        setBlockchainsPage(nextPage);
        const indexOfLastItem = nextPage * blockchainsPageSize;
        const indexOfFirstItem = indexOfLastItem - blockchainsPageSize;
        const currentPageItems = data.slice(indexOfFirstItem, indexOfLastItem);
        setBlockchains(currentPageItems);
    };

    const handlePageSizeChange = (activeItem) => {
        setBlockchainsPageSize(activeItem.title);
        const tempBlockchains = data.slice(0, activeItem.title);
        setBlockchains(tempBlockchains);
        setBlockchainsPage(1);

        const blockchainsStates = JSON.parse(localStorage.getItem("blockchainsStates"));
        localStorage.setItem(
            "blockchainsStates",
            JSON.stringify({ ...blockchainsStates, pageSize: activeItem.title })
        );
    };

    useEffect(() => {
        const tempBlockchains = data.slice(
            (blockchainsPage - 1) * blockchainsPageSize,
            blockchainsPage * blockchainsPageSize
        );
        setBlockchains(tempBlockchains);
    }, [data]);

    useEffect(() => {
        setBlockchainsTotalPages(Math.ceil(data.length / blockchainsPageSize));
    }, [blockchains]);

    const { handleOnClick, getSortIcon } = useBlockchainsSort({ requestedData, setRequestedData });

    return (
        <>
            <div className="scroll shadow bg-white" style={{ padding: 0 }}>
                {isLoading.blockchainDataIsLoading ? (
                    <TableSkeleton message="Loading..." icon={textPreloader} />
                ) : (
                    <table id="blockchains-table" className="table blockchains-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-hash text-start">
                                    <span
                                        data-tooltip-id="tooltip-blockchains-table-txn-hash"
                                        data-tooltip-content={`Transaction Hash (unique ID).`}
                                    >
                                        Txn Hash
                                    </span>

                                    <Tooltip
                                        id="tooltip-blockchains-table-txn-hash"
                                        className="custom-tooltip"
                                    />
                                </th>
                                <th className="table-th-age">
                                    <span
                                        data-tooltip-id="tooltip-blockchains-table-age"
                                        data-tooltip-content={`Time since event (timestamp).`}
                                        className="with-sort"
                                        onClick={() => {
                                            // setSelectedQuery('')
                                            handleOnClick("timeStamp", null, () => {});
                                        }}
                                    >
                                        Age {getSortIcon("timeStamp")}
                                    </span>

                                    <Tooltip id="tooltip-blockchains-table-age" className="custom-tooltip" />
                                </th>
                                <th className="table-th-from">
                                    <span
                                        data-tooltip-id="tooltip-blockchains-table-from"
                                        data-tooltip-content={`Sender's address.`}
                                    >
                                        From
                                    </span>

                                    <Tooltip id="tooltip-blockchains-table-from" className="custom-tooltip" />
                                </th>
                                <th className="table-th-to">
                                    <span
                                        data-tooltip-id="tooltip-blockchains-table-to"
                                        data-tooltip-content={`Recipient's address.`}
                                    >
                                        To
                                    </span>

                                    <Tooltip id="tooltip-blockchains-table-to" className="custom-tooltip" />
                                </th>
                                <th
                                    className="table-th-quantity"
                                    onClick={() => handleOnClick("value", null, () => {})}
                                >
                                    <span
                                        data-tooltip-id="tooltip-blockchains-table-quantity"
                                        data-tooltip-content={`Amount of transactions.`}
                                        className="with-sort"
                                    >
                                        Amount {getSortIcon("value")}
                                    </span>

                                    <Tooltip
                                        id="tooltip-blockchains-table-quantity"
                                        className="custom-tooltip"
                                    />
                                </th>
                                {isAdminTeamMember && (
                                    <th className="table-th-highlight">
                                        <span
                                            data-tooltip-id="tooltip-table-admin-team"
                                            // data-tooltip-content={`Action available only<br/> for Blockchain-Ads team members`}
                                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                <span>
                                                    Action available for Blockchain-Ads
                                                    <br /> team members
                                                </span>
                                            )}
                                        >
                                            <Checkbox
                                                checked={true}
                                                color="dark"
                                                size="sm"
                                                disabled={true}
                                                // label="Mark"
                                            />
                                            <Tooltip
                                                id="tooltip-table-admin-team"
                                                className="custom-tooltip"
                                            />
                                        </span>
                                    </th>
                                )}
                            </tr>
                        </thead>

                        <tbody
                        // className={`${tempCurrentPage ? "hide" : ""}`}
                        // onTransitionEnd={() => {
                        //     if (tempCurrentPage) setCurrentPage(tempCurrentPage);
                        // }}
                        >
                            {!blockchains.length ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                blockchains.map((item, index) => (
                                    <BlockchainRow
                                        key={index}
                                        item={item}
                                        index={index}
                                        blockchains={blockchains}
                                    />
                                ))
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.blockchainDataIsLoading && !blockchains.length && (
                    <div className="no-data-cover"></div>
                )}
            </div>
            {!blockchains.length ? (
                ""
            ) : (
                <div className={`pagination-wrapper  ${isLoading.blockchainDataIsLoading ? "on-hold" : ""}`}>
                    <Pagination
                        callback={handlePagination}
                        currentPage={blockchainsPage}
                        totalPages={blockchainsTotalPages}
                        isLoading={isLoading.blockchainDataIsLoading}
                    />
                    <div className="wrapper">
                        <p>items per page</p>
                        <Select
                            {...{
                                items: blockchainsItemsPerPage,
                                setItems: setBlockchainsItemsPerPage,
                                placeholder: "select 1",
                                variant: "outlined",
                                color: "dark",
                                size: "sm",
                                callback: handlePageSizeChange,
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

const BlockchainRow = ({ item, index, blockchains }) => {
    const {
        getShortText,
        isDarkModeEnabled,
        isAdminTeamMember,
        setIsLoading,
        requestedData,
        setRequestedData,
        errorAlert,
        successAlert,
        getLoadCampaignData,
        blockchainsState
    } = useGlobalContext();
    const {activeNetwork} = blockchainsState
    const { eye, eyeSlash } = localData.svgs;
    const { updateTransaction, sumTransactionQuantities } = useFetch();
    const { addCommas } = useFormatter();
    const { copyText2 } = useUtil();

    function formatAgeFromTimeStamp(timeStamp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const ageInSeconds = currentTime - parseInt(timeStamp);

        if (ageInSeconds >= 86400) {
            // More than a day
            const days = Math.floor(ageInSeconds / 86400);
            return `${days} day${days !== 1 ? "s" : ""} ago`;
        } else if (ageInSeconds >= 3600) {
            // More than an hour
            const hours = Math.floor(ageInSeconds / 3600);
            return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
        } else if (ageInSeconds >= 60) {
            // More than a minute
            const minutes = Math.floor(ageInSeconds / 60);
            return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
        } else {
            // Seconds
            return `${ageInSeconds} second${ageInSeconds !== 1 ? "s" : ""} ago`;
        }
    }

    const [isChecked, setIsChecked] = useState(false);
    const [isCheckboxLoading, setIsCheckboxLoading] = useState(false);

    useEffect(() => {
        setIsChecked((prev) => item.isMarked);
    }, [blockchains]);

    // UPDATE TRANSACTION
    const _updateTransaction = async ({
        txn_hash = "",
        from = "",
        to = "",
        age = "",
        quantity = null,
        _isChecked = false,
        smartContractAddress = "",
        network = ""
    }) => {
        // setIsLoading((prev) => ({ ...prev, updateTransactionIsLoading: true }));
        setIsCheckboxLoading(true);
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            // uuid: auth.currentUser.uid,
            txn_hash,
            to,
            from,
            age,
            quantity,
            flag: _isChecked,
            smartContractAddress,
            network
            // organizationID: requestedData.userProfileData.currentOrganization.id,
        };
        try {
            const data = await updateTransaction(() => {}, tempRaw);
            console.log(data, "=_updateTransaction= request ");
            setIsChecked(_isChecked);
            // getLoadCampaignData(organizationID);
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=_updateTransaction= request error ");
        }
        // setIsLoading((prev) => ({ ...prev, updateTransactionIsLoading: false }));
        setIsCheckboxLoading(false);
    };

    return (
        <>
            <tr className={`table-row ${isChecked ? "table-row-marked" : ""}`}>
                <td className="table-td-transaction text-start">
                    {/* {(
                        <Popup
                            toggler={<div className="blockHash">{item.hash}</div>}
                            content={item.hash}
                            position="right"
                            color={`${isDarkModeEnabled ? "light" : "dark"}`}
                        />
                    ) || "..."} */}
                    <div className="wrapper">
                        <span
                            data-tooltip-id={`tooltip-blockchains-table-td-hash-${index}`}
                            data-tooltip-content={item.hash || item.hash}
                        >
                            {item.txn_hash || item.hash}
                            <Tooltip
                                id={`tooltip-blockchains-table-td-hash-${index}`}
                                className="custom-tooltip"
                            />
                        </span>
                        <CopyTextButton text={item.hash} />
                    </div>
                </td>
                <td className="table-td-age text-center">{formatAgeFromTimeStamp(item.age || item.timeStamp)}</td>
                <td className="table-td-from text-center">
                    {/* {(
                        <Popup
                            toggler={getShortText(item.from)}
                            content={item.from}
                            position="top"
                            color={`${isDarkModeEnabled ? "light" : "dark"}`}
                        />
                    ) || "..."} */}
                    <span
                        data-tooltip-id={`tooltip-blockchains-table-td-from-${index}`}
                        data-tooltip-content={item.from}
                    >
                        {getShortText(item.from)}
                        <Tooltip
                            id={`tooltip-blockchains-table-td-from-${index}`}
                            className="custom-tooltip"
                        />
                    </span>
                </td>
                <td className="table-td-to text-center">
                    {/* {(
                        <Popup
                            toggler={getShortText(item.to)}
                            content={item.to}
                            position="top"
                            color={`${isDarkModeEnabled ? "light" : "dark"}`}
                        />
                    ) || "..."} */}
                    <span
                        data-tooltip-id={`tooltip-blockchains-table-td-to-${index}`}
                        data-tooltip-content={item.to}
                    >
                        {getShortText(item.to)}
                        <Tooltip id={`tooltip-blockchains-table-td-to-${index}`} className="custom-tooltip" />
                    </span>
                </td>
                <td className="table-td-quantity text-center">
                    {item.quantity ? addCommas(item.quantity) : addCommas((parseFloat(item.value) / 10 ** 18).toFixed(10))}
                </td>
                {isAdminTeamMember && (
                    <td className="table-td-highlight">
                        <div
                            className={`checkbox-wrapper  ${isCheckboxLoading ? "on-hold spin-loading" : ""}`}
                        >
                            <label className={`form-check  form-check-sm`}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked}
                                    onChange={() => {}}
                                />
                                <Button
                                    onClick={() =>
                                        _updateTransaction({
                                            txn_hash: item.hash,
                                            from: item.from,
                                            to: item.to,
                                            age: item.timeStamp,
                                            quantity: parseFloat(item.value) / 10 ** 18,
                                            _isChecked: !isChecked,
                                            smartContractAddress: item.smartContractAddress,
                                            network: activeNetwork
                                        })
                                    }
                                    className={`btn form-check-btn btn-circle-purple`}
                                >
                                    <div className="form-check-checkbox"></div>
                                </Button>
                            </label>
                        </div>
                    </td>
                )}
            </tr>
        </>
    );
};
