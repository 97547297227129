import React, { useState, useEffect, useRef } from "react";
import { TableSkeleton, Select, Button, Popup, Pagination, Checkbox, Carousel } from "../../../../components";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import useFormatter from "../../../../hooks/useFormatter.jsx";
import { Link, useNavigate } from "react-router-dom";

import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { useStateContext } from "../../../../stateContext";
import { auth } from "../../../../config/firebase.js";
import useFetch from "../../../../hooks/useFetch.js";
import { v4 as uuidv4 } from "uuid";

let timeout = "";

export default function PublicationsTable() {
    const {
        requestedData,
        isLoading,
        isAdminTeamMember,
        marketplaceState,
        marketplaceTheadRef,
        marketplaceScrollItems,
    } = useGlobalContext();
    const { publications } = marketplaceState;
    const [filteredPublications, setFilteredPublications] = useState([]);

    const { textPreloader } = localData.images;
    const { order } = localData.svgs;

    useEffect(() => {
        const filtered = publications.filter((item) => !item.isFeatured);
        setFilteredPublications(filtered);
    }, [publications]);

    const [activePackageId, setActivePackageId] = useState("");

    return (
        <div className="publications-wrapper">
            <div className="scroll-items" ref={marketplaceScrollItems}>
                <Carousel
                    // items={[
                    //     {
                    //         startIcon: null,
                    //         title: "Featured",
                    //         value: "Featured",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Tech 1",
                    //         value: "TechStartupsPackage1",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Tech 2",
                    //         value: "TechStartupsPackage2",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Crypto 1",
                    //         value: "CryptoPackage1",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Crypto 2",
                    //         value: "CryptoPackage2",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Crypto 3",
                    //         value: "CryptoPackage3",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Gaming",
                    //         value: "GamingPackage",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Finance 1",
                    //         value: "FinancePackage1",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Finance 2",
                    //         value: "FinancePackage2",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Press-Release 1",
                    //         value: "PressReleasePackage1",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Press-Release 2",
                    //         value: "PressReleasePackage2",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Crypto-Release 1",
                    //         value: "CryptoReleasesPack1",
                    //     },
                    //     {
                    //         startIcon: null,
                    //         title: "Crypto-Release 2",
                    //         value: "CryptoReleasesPack2",
                    //     },
                    // ]}
                    Card={(item, index) => {
                        return (
                            <Button size="sm" color="dark">
                                <a
                                    href={"#" + item.value}
                                    className={`scroll-item-link ${activePackageId == item.value ? "active" : ""}`}
                                    onClick={() => setActivePackageId(item.value)}
                                >
                                    {item.title}
                                </a>
                            </Button>
                        );
                    }}
                    options={{
                        slidesPerView: "auto",
                        breakpoints: null,
                        loop: false,
                        autoplay: false,
                        speed: 500,
                        spaceBetween: 3,
                    }}
                    items={filteredPublications.map((item) => {
                        return {
                            startIcon: null,
                            title: item.packageShortName,
                            value: item.id,
                        };
                    })}
                />
            </div>
            <br />
            <div className="scroll bg-white" style={{ padding: 0 }}>
                {isLoading.userProfileIsLoading ? (
                    <TableSkeleton message="Loading..." icon={textPreloader} />
                ) : (
                    <table id="publications-table" className="table publications-table">
                        <thead ref={marketplaceTheadRef} className="publications-table-thead">
                            <tr className="table-row">
                                <th className="table-th-media text-start">Media</th>
                                {/* <th className="table-th-settings">
                                    <Button variant="circle">{heart}</Button>
                                </th> */}
                                <th className="table-th-format text-start">Format</th>
                                <th className="table-th-monthly-traffic text-start">Monthly Traffic</th>
                                <th className="table-th-to text-start">Turnaround Time</th>
                                {/* <th className="table-th-price text-center">Price</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {!publications.length ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {publications.map((item, index) => {
                                        if (item.isFeatured) return;
                                        return item.products.map((subItem, subIndex) => {
                                            let isFirstItem = subIndex == 0;
                                            let isLastItem = subIndex == item.products.length - 1;

                                            return (
                                                <PublicationRow
                                                    key={uuidv4()}
                                                    {...item}
                                                    {...subItem}
                                                    _package={item}
                                                    packageId={item.id}
                                                    index={index}
                                                    subIndex={subIndex}
                                                    isFirstItem={isFirstItem}
                                                    isLastItem={isLastItem}
                                                    activePackageId={activePackageId}
                                                />
                                            );
                                        });
                                    })}
                                </>
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.blockchainDataIsLoading && !publications.length && (
                    <div className="no-data-cover"></div>
                )}
            </div>
        </div>
    );
}

const PublicationRow = ({
    id,
    packageId,
    media,
    format,
    monthlyTraffic = "",
    reach = "",
    turnaroundTime,
    duration,
    isInShoppingCart,
    isInWishlist,
    cover,
    productTitles,
    price,
    discount,
    included,
    packageType,
    packageName,
    isFirstItem,
    isLastItem,
    index,
    subIndex,
    totalPrice,
    extraSource = [],
    _package,
    activePackageId,
}) => {
    const { marketplaceState } = useGlobalContext();

    const { publications, setPublications, setActiveMarketplaceDetails } = marketplaceState;

    const { eye, eyeSlash, heart, shoppingCart, info, arrowRight } = localData.svgs;
    const { addCommas } = useFormatter();

    // const handleAddToShoppingCart = () => {
    //     let tempPublications = [...publications];
    //     tempPublications = tempPublications.map((item) =>
    //         item.id == id ? { ...item, isInShoppingCart: !isInShoppingCart } : { ...item }
    //     );
    //     setPublications(tempPublications);
    // };

    // const handleAddToWishlist = () => {
    //     let tempPublications = [...publications];
    //     tempPublications = tempPublications.map((item) =>
    //         item.id == id ? { ...item, isInWishlist: !isInWishlist } : { ...item }
    //     );
    //     setPublications(tempPublications);
    // };

    // const handleRemoveFromShoppingCart = () => {
    //     let tempPublications = [...publications];
    //     tempPublications = tempPublications.map((item) =>
    //         item.id == id ? { ...item, isInShoppingCart: false } : { ...item }
    //     );
    //     setPublications(tempPublications);
    // };

    // const handleRemoveFromWishlist = () => {
    //     let tempPublications = [...publications];
    //     tempPublications = tempPublications.map((item) =>
    //         item.id == id ? { ...item, isInWishlist: false } : { ...item }
    //     );
    //     setPublications(tempPublications);
    // };

    // const handleCalc = (name) => {
    //     const allCheckboxes = document.body.querySelectorAll(".checkbox-" + name);

    //     let tempTotalPrice = 0;

    //     allCheckboxes.forEach((item) => {
    //         const isChecked = item.querySelector("input").checked;
    //         if (isChecked) {
    //             const price = item.closest("tr").querySelector(".table-td-price");
    //             if (price) {
    //                 const value = parseFloat(price.innerText.replace(/[^0-9.]/g, ""));
    //                 tempTotalPrice += value;
    //             }
    //         }
    //     });
    //     const totalPriceElement = document.querySelector("#" + name + " .total-price-value");
    //     totalPriceElement.innerHTML = tempTotalPrice;
    // };

    // const handleAllCheckboxes = (name, isChecked) => {
    //     const allCheckboxes = document.body.querySelectorAll(".checkbox-" + name);
    //     allCheckboxes.forEach((item) => (item.querySelector("input").checked = isChecked));
    //     handleCalc(name);
    // };

    // const [wishlistItems, setWishlistItems] = useState([]);
    // const [shoppingCartItems, setShoppingCartItems] = useState([]);

    // const handleAddAllToWishlist = () => {
    //     const tempPublications = publications.map((item) =>
    //         item.packageType == packageType ? { ...item, isInWishlist: true } : item
    //     );
    //     setPublications(tempPublications);
    // };
    // const checkWishList = () => {
    //     const tempWishlistItems = publications.filter(
    //         (item) => item.packageType == packageType && item.isInWishlist && item
    //     );
    //     setWishlistItems(tempWishlistItems);
    // };

    // const handleAddAllToShoppingCart = () => {
    //     const tempPublications = publications.map((item) =>
    //         item.packageType == packageType ? { ...item, isInShoppingCart: true } : item
    //     );
    //     setPublications(tempPublications);
    // };

    // const checkSoppingCart = () => {
    //     const tempShoppingCartItems = publications.filter(
    //         (item) => item.packageType == packageType && item.isInShoppingCart && item
    //     );
    //     setShoppingCartItems(tempShoppingCartItems);
    // };

    // useEffect(() => {
    //     checkWishList();
    //     checkSoppingCart();
    // }, [publications]);

    const navigate = useNavigate();

    // const handlePackage = () => {
    //     const tempPublications = publications.map((item) =>
    //         item.packageName == packageName ? { ...item, isInShoppingCart: !item.isInShoppingCart } : item
    //     );
    //     setPublications(tempPublications);
    // };

    const handleAddToShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == packageId ? { ...item, isInShoppingCart: !isInShoppingCart } : { ...item }
        );
        setPublications(tempPublications);
    };

    const handleAddToWishlist = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == packageId ? { ...item, isInWishlist: !isInWishlist } : { ...item }
        );
        setPublications(tempPublications);
    };

    const handelNavigateToShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == packageId ? { ...item, isInShoppingCart: true } : { ...item }
        );
        setPublications(tempPublications);

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            navigate("/additional-products/pr/shopping-cart");
        }, 500);
    };

    const Thead = () => (
        <tr className="table-row secondary-thead">
            <th className="table-th-media text-start">Media</th>
            {/* <th className="table-th-settings">
        <Button variant="circle">{heart}</Button>
    </th> */}
            <th className="table-th-format text-start">Format</th>
            <th className="table-th-monthly-traffic text-start">Monthly Traffic</th>
            <th className="table-th-to text-start">Turnaround Time</th>
            {/* <th className="table-th-price text-center">Price</th> */}
        </tr>
    );
    return (
        <>
            {subIndex == 0 && <tr id={packageId} className="top"></tr>}
            {index !== 5 && isFirstItem && <Thead />}
            {isFirstItem && (
                <>
                    <tr className={`table-tr-summary ${
                    packageId == activePackageId ? "active" : ""
                }`} >
                        {/* <td></td> */}
                        {/* <td className="table-td-all-settings">
                            <div className="btn-group">
                                <Button
                                    className="wishlist-btn"
                                    variant="circle"
                                    size=""
                                    color="dark"
                                    // onClick={handleAddAllToWishlist}
                                >
                                    {heart}
                                    {wishlistItems.length ? (
                                        <div className="badge">{wishlistItems.length}</div>
                                    ) : (
                                        ""
                                    )}
                                </Button>
                                <Button className="shopping-cart-btn" variant="circle" size="" color="dark">
                                    {shoppingCart}
                                    {shoppingCartItems.length ? (
                                        <div className="badge badge-success">{shoppingCartItems.length}</div>
                                    ) : (
                                        ""
                                    )}
                                </Button>
                            </div>
                        </td> */}
                        <td colSpan={4}>
                            <h2 className="">{packageName}</h2>
                        </td>
                    </tr>
                </>
            )}

            <tr
                className={`table-row ${false ? "table-row-marked" : ""} ${
                    packageId == activePackageId ? "active" : ""
                }`}
            >
                <td className="table-td-media text-start"  title={media}>
                    {media}
                </td>

                <td className="table-td-format ">{format}</td>
                <td className="table-td-montdly-traffic ">
                    {addCommas(monthlyTraffic.toString())}
                    {addCommas(reach.toString())}
                </td>
                <td className="table-td-to ">{turnaroundTime || duration}</td>
            </tr>

            {isLastItem && (
                <>
                    {!extraSource.length ? (
                        ""
                    ) : (
                        <tr className={`table-tr-extra-source ${
                            packageId == activePackageId ? "active" : ""
                        }`}>
                            <td colSpan={6}>{extraSource[0]}</td>
                        </tr>
                    )}
                    <tr style={{ border: "none" }} id={packageType} className="table-tr-footer">
                        <td>
                            <h2 className="total-price">
                                Total Price $
                                <span className="total-price-value">{addCommas(totalPrice)}</span> USD
                            </h2>
                        </td>

                        <td colSpan={2}>
                            <Button
                            // id={index == 5 ? `pr-tutorial-3` : `btn-${uuidv4()}`}
                                className="details-btn"
                                size="sm"
                                color="secondary"
                                onClick={() => setActiveMarketplaceDetails({ ..._package })}
                            >
                                What’s included {info}
                            </Button>
                        </td>

                        <td className="table-td-footer" style={{ width: 0 }}>
                            <div className="wrapper">
                                {/* <Button
                                    className={`footer-btn-left add-to-cart-btn ${
                                        isInShoppingCart ? "active" : ""
                                    }`}
                                    size="sm"
                                    onClick={handlePackage}
                                    color={`${"marketplace"}`}
                                    name={`${"add to cart"}`}
                                    endIcon={shoppingCart}
                                /> */}
                                <Button
                                    className={`${isInWishlist ? "active" : ""} circle-btn`}
                                    variant="circle"
                                    size=""
                                    onClick={handleAddToWishlist}
                                    color={`${"primary"}`}
                                >
                                    {heart}
                                </Button>

                                <Button
                                    className={`${isInShoppingCart ? "active" : ""} circle-btn`}
                                    variant="circle"
                                    onClick={handleAddToShoppingCart}
                                    color={`${"success"}`}
                                >
                                    {shoppingCart}
                                </Button>

                                <Button
                                    className="order-btn"
                                    color="marketplace"
                                    variant="contained"
                                    size="sm"
                                    onClick={handelNavigateToShoppingCart}
                                >
                                    <div>Add to cart</div>
                                    {/* <span className="price"> $ {addCommas(totalPrice) || 0}</span> */}
                                </Button>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td style={{ height: "60px", backgroundColor: "#fff" }}></td>
                    </tr>
                </>
            )}
        </>
    );
};