import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import AdminAccountCreation from "./admin-pages/CreateAccountAdminPage";
import { Accounts } from "../../components";
import ReportsPage from "./admin-pages/ReportsPage"

const UserManagement = () => <div className="modal-dialog-centered modal-fullscreen modal-dialog-scrollable accounts"><Accounts /></div> ;
const Reports = () => <ReportsPage />;

export default function AdminPanel() {
  const [activeTab, setActiveTab] = useState(2);

  // Tab change handler
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", background: "#f0f2f5", height: "calc(100vh - 63px)" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        aria-label="Admin Panel Tabs"
      >
        <Tab label="Reports" />
        <Tab label="User Management" />
        <Tab label="Create Account" />
      </Tabs>

      <Box sx={{ p: 3 }}>
      {activeTab === 0 && <Reports />}
        {activeTab === 1 && <UserManagement />}
        {activeTab === 2 && <AdminAccountCreation />}
        
      </Box>
    </Box>
  );
}
