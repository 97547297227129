import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import { Button, Drawer, Tabs } from "../../../components";
import InstallPixelTab from "../tracking-drawer/tracking-tabs/install-pixel-tab/InstallPixelTab";
import CreateConversionTab from "../tracking-drawer/tracking-tabs/create-conversion-tab/CreateConversionTab";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const { arrowLeft, plus } = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function CreatePixel({ name = "Setup Tracking" }) {
  const { requestedData, trackingState } = useGlobalContext();

  const {
    displayInstallPixel,
    setDisplayInstallPixel,
    setIsEditingMode,
    setDefaultTab,
    setEditingTagId,
    resetState,
  } = trackingState;

  return (
    <div className="tracking-drawer">
      <DrawerComponent />
    </div>
  );
}

const DrawerComponent = ({ callbackFromParent }) => {
  const { requestedData, trackingState, isConversionModalOpen, setIsConversionModalOpen } =
    useGlobalContext();

  const { defaultTab, setDefaultTab, isEditingMode } = trackingState;

  const [activeTrackingTab, setActiveTrackingTab] = useState();

  const { close, arrowDown, arrowUp } = localData.svgs;

  const activeStepTitle =
    activeTrackingTab == "--install Pixel (step 1)"
      ? "Blockchain-Ads Tracking Script"
      : "Conversion Details";

  const activeStepDescription =
    activeTrackingTab == "--install Pixel (step 1)" ? "" : "";

  const navigate = useNavigate();

  const [isCreateConversionPixel, setIsCreateConversionPixel] = useState(
    isEditingMode ? true : false
  );

  const handleAccordionToggle = () => {
    setIsConversionModalOpen((prev) => !prev);
  };
  
  useEffect(() => {
    setIsConversionModalOpen(false)
  }, [])
  
  return (
    <div className={`${isEditingMode ? "editing-mode" : ""}`}>
      <div className="tracking-page-header">
        <h2 className="display-2">{activeStepTitle}</h2>
        <span
          onClick={() => {
            navigate("/tracking");
          }}
          className="close-icon"
        >
          {close}
        </span>
      </div>
      <p className="text-style-1">
        Follow the instructions on this page to start tracking conversions from
        your Blockchain-Ads Advertising campaigns and increase your results.
      </p>

      <p className="install-pixel-tab-description text-style-1">
        {activeStepDescription}
      </p>
      {/* <p className="text-style-1">
                Set up conversion tracking with Blockchain-Ads to measure specific user actions on your
                website.
            </p> */}
      {/* <div>step 1 ----------- step 2 ---------- step 3</div> */}

      {/* <Tabs
                defaultTab={defaultTab}
                tabsProps={{ callbackFromParent }}
                tabs={trackingTabs}
                title="Setup Tracking"
                // indicatorIcon={question}
                className="tracking-tabs"
                callback={setActiveTrackingTab}
            /> */}
      <InstallPixelTab
        setIsCreateConversionPixel={setIsCreateConversionPixel}
      />
      <br />
      <Accordion
        expanded={isConversionModalOpen}
        style={{
          border: "2px solid rgba(219, 222, 225,1)",
          borderRadius: "14px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ fontWeight: "bold" }}
          onClick={handleAccordionToggle}
        >
          <span className="green-title-accordion">Conversions</span> Pixel
        </AccordionSummary>
        <AccordionDetails>
          <CreateConversionTab />
        </AccordionDetails>
      </Accordion>
      <br />
      <br />
      <br />
      {/* <div className={`tabs shadow d-flex custom-border`} >
                <div className="create-pixel-header">
                <h3>Conversions Pixel</h3>
                <span style={{width: "10px", cursor: "pointer"}} onClick={handleConversionPixelClick}>{!isCreateConversionPixel ? arrowDown : arrowUp}</span>
                </div>
            
                <div className="create-pixel-body" id="create-convresions-pixel">
                    {isCreateConversionPixel ? <CreateConversionTab /> : <p></p>}
                </div>         
            </div> */}
    </div>
  );
};

const ConfirmationTab = ({ setActiveTab }) => {
  return (
    <div>
      confirmation tab
      <br />
      <br />
      <br />
      <Button
        name="back"
        color="pale-dark"
        startIcon={arrowLeft}
        size="sm"
        onClick={() => setActiveTab(1)}
      />
    </div>
  );
};

const trackingTabs = [
  {
    title: "--install Pixel (step 1)",
    content: <InstallPixelTab />,
    id: "install-pixel-tab-eifoei2983yr23",
  },
  {
    title: "--create conversion (step 2)",
    content: <CreateConversionTab />,
    id: "create-conversion-tab-jfldsjfl23",
  },
  {
    title: "--confirmation (step 3)",
    content: <ConfirmationTab />,
    id: "confirmation-tab-jfldsjfl23",
  },
];
