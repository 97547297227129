import React, { useState, useEffect, useRef } from "react";
import { TableSkeleton, Select, Button, Popup, Pagination, Switch } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { useStateContext } from "../../../../stateContext";
import useFormatter from "../../../../hooks/useFormatter";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

const ITEMSPERPAGE = [
    { title: 10, isSelected: false, id: uuidv4() },
    { title: 20, isSelected: true, id: uuidv4() },
    { title: 50, isSelected: false, id: uuidv4() },
    { title: 100, isSelected: false, id: uuidv4() },
];

export default function Publishers() {
    const {
        requestedData,
        setRequestedData,
        isLoading,
        tooManyRequestsError,
        requestedData: {
            campaignStatistics: { tab },
        },
        handleOnClick,
        getSortIcon,
    } = useGlobalContext();

    const { textPreloader } = localData.images;
    const { arrowDown, sortDown, sortUp } = localData.svgs;

    const [rows, setRows] = useState([]);
    const [rowsPage, setRowsPage] = useState(1);
    const [rowsPageSize, setRowsPageSize] = useState(10);
    const [rowsTotalPages, setRowsTotalPages] = useState(10);

    const [rowsItemsPerPage, setRowsItemsPerPage] = useState(ITEMSPERPAGE);

    useEffect(() => {
        const rowsStates = JSON.parse(localStorage.getItem("rowsStates"));
        if (rowsStates?.pageSize) {
            let tempItemsPerPage = [...rowsItemsPerPage];
            tempItemsPerPage = tempItemsPerPage.map((item) => ({
                title: item.title,
                isSelected: item.title === rowsStates.pageSize,
                id: item.id,
            }));
            setRowsItemsPerPage(tempItemsPerPage);
            setRowsPageSize(rowsStates.pageSize);
        }
    }, []);

    const handlePagination = (nextPage = 0) => {
        setRowsPage(nextPage);
        const indexOfLastItem = nextPage * rowsPageSize;
        const indexOfFirstItem = indexOfLastItem - rowsPageSize;
        const currentPageItems = requestedData.campaignStatistics.items.slice(
            indexOfFirstItem,
            indexOfLastItem
        );
        setRows(currentPageItems);
    };

    const handlePageSizeChange = (activeItem) => {
        setRowsPageSize(activeItem.title);
        const tempRows = requestedData.campaignStatistics.items.slice(0, activeItem.title);
        setRows(tempRows);
        setRowsPage(1);

        const rowsStates = JSON.parse(localStorage.getItem("rowsStates"));
        localStorage.setItem("rowsStates", JSON.stringify({ ...rowsStates, pageSize: activeItem.title }));
    };

    useEffect(() => {
        const tempRows = requestedData.campaignStatistics.items.slice(
            (rowsPage - 1) * rowsPageSize,
            rowsPage * rowsPageSize
        );
        setRows(tempRows);
    }, [requestedData.campaignStatistics.items]);

    useEffect(() => {
        setRowsTotalPages(Math.ceil(requestedData.campaignStatistics.items.length / rowsPageSize));
    }, [rows]);

    const params = useParams();

    const [currentCampaign, setCurrentCampaign] = useState({});

    useEffect(() => {
        const tempCurrentCampaign = requestedData.campaignData.currentOrganizationCampaigns.find(
            (item) => item.id == params.id
        );
        setCurrentCampaign(tempCurrentCampaign);
    }, [requestedData.campaignData.currentOrganizationCampaigns]);

    return (
        <div className="zones-wrapper">
            <div className="scroll shadow bg-white" style={{ padding: 0 }}>
                {isLoading.campaignStatisticsLoading ? (
                    <TableSkeleton
                        message={`Processing data on server - please wait...  `}
                        icon={textPreloader}
                    />
                ) : (
                    <table id="publishers-table" className="table publishers-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-switch text-center"></th>
                                <th
                                    className="table-th-hash text-center"
                                >
                                 Publisher ID 
                                </th>
                                <th
                                    className="table-th-age text-center clickable"
                                    onClick={() =>
                                        handleOnClick("impressions", null, () => handlePagination(1))
                                    }
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                        className="with-sort"
                                    >
                                        Impressions {getSortIcon("impressions")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-from text-center clickable"
                                    onClick={() => handleOnClick("clicks", null, () => handlePagination(1))}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                        className="with-sort"
                                    >
                                        Clicks {getSortIcon("clicks")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("ctr", null, () => handlePagination(1))}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ctr"
                                        data-tooltip-content={` How often your ads are clicked when viewed by Blockchain-Ads users.`}
                                        className="with-sort"
                                    >
                                        CTR {getSortIcon("ctr")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("spent", null, () => handlePagination(1))}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={` How much budget has been spent.`}
                                        className="with-sort"
                                    >
                                        Ad Spend {getSortIcon("spent")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("cpc", null, () => handlePagination(1))}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-cpc"
                                        data-tooltip-content={`How much you pay for each click.`}
                                        className="with-sort"
                                    >
                                        CPC {getSortIcon("cpc")}
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!rows.length || tab.toLowerCase() !== "publishers" ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                rows.map((item, index) => (
                                    <ZonesRow
                                        key={index}
                                        item={item}
                                        index={index}
                                        currentCampaign={currentCampaign}
                                        setCurrentCampaign={setCurrentCampaign}
                                        rows={rows}
                                    />
                                ))
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.campaignStatisticsLoading && !rows.length && (
                    <div className="no-data-cover"></div>
                )}
            </div>
            {!rows.length ? (
                ""
            ) : (
                <div
                    className={`pagination-wrapper  ${isLoading.campaignStatisticsLoading ? "on-hold" : ""}`}
                >
                    <Pagination
                        callback={handlePagination}
                        currentPage={rowsPage}
                        totalPages={rowsTotalPages}
                        isLoading={isLoading.campaignStatisticsLoading}
                    />
                    <div className="wrapper">
                        <p>items per page</p>
                        <Select
                            {...{
                                items: rowsItemsPerPage,
                                setItems: setRowsItemsPerPage,
                                placeholder: "select 1",
                                variant: "outlined",
                                color: "dark",
                                size: "sm",
                                callback: handlePageSizeChange,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

const ZonesRow = ({ item, index, currentCampaign, setCurrentCampaign, rows }) => {
    const { getShortText, isDarkModeEnabled } = useGlobalContext();
    const { eye, eyeSlash } = localData.svgs;
    const { formatNumber, formatCurrency } = useFormatter();
    const { requestedData, isLoading, errorAlert } = useGlobalContext();
    const { startOrStopCampaignZone } = useFetch();

    const params = useParams();

    const [isStartOrStopLoading, setIsStartOrStopLoading] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const filterCurrentCampaign = ({ isPaused, zoneId }) => {
        const tempCurrentCampaign = { ...currentCampaign };
        const tempPausedZones = [...tempCurrentCampaign.pausedZones];
        let filteredPausedZones = [];

        if (isPaused) {
            filteredPausedZones = tempPausedZones.filter((item) => item != zoneId);
        } else {
            filteredPausedZones = tempPausedZones.includes(zoneId)
                ? tempPausedZones
                : [...tempPausedZones, zoneId];
        }

        setCurrentCampaign({ ...currentCampaign, pausedZones: filteredPausedZones });
    };

    const getStartOrStopCampaignZone = async (campaignId = null, zoneId = null, toPlayZone = true) => {
        setIsStartOrStopLoading(true);
        const organizationId = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            campaignId,
            zoneId,
            organizationId,
            toPlayZone,
        };
        try {
            const data = await startOrStopCampaignZone(() => {}, tempRaw);
            console.log(data, "=startOrStopCampaignZone= request");
            setIsPaused(!isPaused);
            filterCurrentCampaign({ isPaused, zoneId });
        } catch (err) {
            console.error(err, "=startOrStopCampaignZone= request error");
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        }
        setIsStartOrStopLoading(false);
    };

    useEffect(() => {
        const pausedZones = currentCampaign?.pausedZones;
        if (pausedZones?.length) {
            const isPaused = pausedZones.includes(item.zone_id);
            setIsPaused(isPaused);
        }
    }, [rows, currentCampaign]);

    return (
        <>
            <tr className="table-row">
                <td
                    className="table-td-switch"
                    style={{ width: 0, paddingRight: 0 }}
                    // style={{ display: "flex", justifyContent: "center" }}
                >
                    <Switch
                        disabled={isStartOrStopLoading}
                        color="primary"
                        checked={!isPaused}
                        callback={(toPlayZone) =>
                            getStartOrStopCampaignZone(parseInt(params.id), item.zone_id, toPlayZone)
                        }
                    />
                </td>
                <td className="table-td-transaction text-center" style={{ width: 0 }}>
                    {item.zone_id}
                </td>
                <td className="table-td-age text-center">{formatNumber(item.impressions)}</td>
                <td className="table-td-from text-center">{formatNumber(item.clicks)}</td>

                <td className="table-td-quantity text-center">{item.ctr?.toFixed(2)}%</td>
                <td className="table-td-quantity text-center">{formatCurrency(item.spent, 2)}</td>
                <td className="table-td-cpc text-center">{formatCurrency(item.cpc, 2)}</td>
            </tr>
        </>
    );
};
