import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
} from '@mui/material';
import localData from '../../../localData';

const ResetPasswordConfirmation = () => {
    const {logoSm} = localData.images
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh', backgroundColor: '#f4f6f8' }}
    >
      <Box
        sx={{
          width: 600,
          padding: 4,
          borderRadius: 2,
          boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
          backgroundColor: '#fff',
          textAlign: 'center',
        }}
      >
        {/* Placeholder for Logo */}
        <Box sx={{ marginBottom: 2, maxWidth: "50px", margin: "0 auto", }}>

            <img src={logoSm} />
        </Box>

        {/* Title */}
        <Typography variant="h5" fontWeight="bold">
          Forget Your Password
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginBottom: 3 }}
        >
          Almost Done, enter your new password
        </Typography>

        {/* Password Input Fields */}
        <Box component="form" noValidate autoComplete="off">
          <TextField
            fullWidth
            label="Password"
            type="password"
            margin="normal"
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            margin="normal"
            variant="outlined"
            sx={{ marginBottom: 3 }}
          />

          {/* Reset Button */}
          <Button
            variant="contained"
            fullWidth
            sx={{
              paddingY: 1.5,
              background: "linear-gradient(96deg, #1BA7C3 3.21%, #3BF693 107.26%)",
              color: '#fff',
            }}
          >
            Reset Password
          </Button>
        </Box>

        {/* Sign In Link */}
        <Typography variant="body2" sx={{ marginTop: 3 }}>
          Already have an account?{' '}
          <Link href="/signin" underline="hover" color="#00B268">
            Sign In
          </Link>
        </Typography>
      </Box>
    </Grid>
  );
};

export default ResetPasswordConfirmation;
