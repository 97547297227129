import React, { useEffect, useState } from "react";
import {
  Navbar,
  CampaignsWizard,
  Tabs,
  Modal,
  Button,
  Calendar,
} from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import CampaignsGraph from "./campaigns-graph/CampaignsGraph.jsx";
import CampaignsTable from "./campaigns-table/CampaignsTable.jsx";
// import BlockchainsTable from "./blockchains-table/BlockchainsTable.jsx";
import Blockchains from "./blockchains/Blockchains.jsx";
import CampaignObjectiveModal from "./campaign-objective-modal/CampaignObjectiveModal.jsx";
import CampaignBuildOptionModal from "./campaign-build-option-modal/CampaignBuildOptionModal.jsx";
import Joyride, { STATUS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Checkbox,
  Chip,
  MenuItem,
  ListItemText,
  TextField,
  Grid,
  Tooltip,
} from "@mui/material";

export default function Campaigns() {
  useEffect(() => {
    document.title = "Blockchain-Ads Platform";

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content = "Empower change. Join our campaign today!";
  }, []);

  const { fade } = useGlobalContext().animations;
  const {
    requestedData,
    isLoading,
    getLoadBlockchainData,
    setSelectedObjective,
    blockchainsState,
    setIsLoading,
    setCampaignState,
    setCreatives,
    setCampaignWizardStatus,
    markedCampaigns,
    setMarkedCampaigns,
    defaultCampaign,
    requestedData: {
      userProfileData,
      campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
    },
    campaignsState,
  } = useGlobalContext();

  const { campaignSelectionRange, handleCalendar } = campaignsState;

  const { blockchainTabs, setActiveNetwork } = blockchainsState;

  const { plus, arrowRightExtended, checkbox } = localData.svgs;

  const [showCampaignObjectiveModal, setShowCampaignObjectiveModal] =
    useState("none");
  const [showCampaignBuildOptionModal, setShowCampaignBuildOptionModal] =
    useState("none");

  const navigate = useNavigate();

  const handleCreateCampaign = () => {
    setCampaignState(defaultCampaign);
    setSelectedObjective("");
    setCreatives([]);
    setCampaignWizardStatus("create");
    navigate("/campaigns/create");
  };

  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  
  const maxVisibleChips = 2;

  useEffect(() => {
    const marked = currentOrganizationCampaigns.filter((campaign) =>
      markedCampaigns.some((markedCampaign) => markedCampaign.id === campaign.id)
    );
  
    setSelectedCampaigns(marked);
  }, [currentOrganizationCampaigns, markedCampaigns]);
  

  const handleChange = (event, newValue) => {
    const addedItems = newValue.filter(
      (item) => !selectedCampaigns.some((selected) => selected.id === item.id)
    );
    const removedItems = selectedCampaigns.filter(
      (item) => !newValue.some((selected) => selected.id === item.id)
    );
  
    const updatedMarkedCampaigns = [
      ...markedCampaigns.filter(
        (marked) => !removedItems.some((removed) => removed.id === marked.id)
      ),
      ...addedItems,
    ];
  
    setMarkedCampaigns(updatedMarkedCampaigns);  
    setSelectedCampaigns(newValue);
  };
  

  return (
    <motion.main {...fade} className="main campaigns-page">
      
      <div
        className="campaign-page-header"
      >
        <Grid sx={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "10px"}}>
          <Grid sx={{display: "flex"}} className="campaign-targeting-conatiner">
            <Grid sx={{width: "100%", minWidth: "280px", maxWidth: "280px"}}>
            <Autocomplete
              sx={{
                "& .MuiAutocomplete-listbox": {
                  maxWidth: "400px",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                },
              }}
              multiple
              size="large"
              options={currentOrganizationCampaigns}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              value={selectedCampaigns}
              onChange={handleChange}
              renderTags={(tagValue, getTagProps) => {
                const visibleTags = tagValue.slice(0, maxVisibleChips);
                const extraTagsCount = tagValue.length - visibleTags.length;
                return <></>;
              }}
              renderOption={(props, option, { selected }) => (
                <Tooltip title={option.name} placement="top" arrow>
                  <MenuItem
                    {...props}
                    key={option.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "400px",
                    }}
                  >
                    <Checkbox
                      checked={selected}
                      style={{ marginRight: 8 }}
                      size="small"
                    />
                    <ListItemText
                      primary={option.name}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    />
                  </MenuItem>
                </Tooltip>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Campaigns"
                  placeholder="Search..."
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderWidth: '1px',
                      borderRadius: "8px",
                      borderColor: 'blu#4b4b4be',
                      '& fieldset': {
                        borderWidth: '1px', // Adjust the border width
                        borderColor: '#4b4b4b', // Adjust the border color
                        borderRadius: "8px",
                      },
                      '&:hover fieldset': {
                        borderColor: '#4b4b4b', // Hover color
                        borderRadius: "8px",
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4b4b4b', // Focus color
                        borderRadius: "8px",
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            </Grid>
          <Grid
            sx={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              border: " 1px solid #4b4b4b",
              borderRadius: " 8px",
              marginLeft: " 15px",
            }}
            className="data-range-select-container"
          >
            <Calendar
              className="reports-calendar"
              _selectionRange={campaignSelectionRange}
              callback={handleCalendar}
            />
          </Grid>
          </Grid>
          <Grid>
          <Modal
            restProps={{ setShowCampaignBuildOptionModal }}
            Toggler={() => (
              <Button
                name="Create Campaign"
                variant="contained"
                startIcon={plus}
                color="success"
                id="login-tutorial-3"
                onClick={handleCreateCampaign}
              />
            )}
            Child={CampaignObjectiveModal}
            display={showCampaignObjectiveModal}
            setDisplay={setShowCampaignObjectiveModal}
            className="modal-dialog-centered campaign-objective-modal"
          />
          </Grid>
          </Grid>
          {/* <h4 className="description">
              Visualize and analyze your campaign performance with detailed
              charts showcasing key metrics and trends.
            </h4> */}
         

      </div>
      <div className="campaign-page">
      <div className="" style={{display: "block"}}>
        <h2
              className="campaign-title display-2"
              style={{ marginBottom: "5px" }}
            >
              Campaign Insights
          </h2>
        </div>
        <CampaignsJoyride />
          {/* <div className="col">
            <h2
              className="campaign-title display-2"
              style={{ marginBottom: "5px" }}
            >
              Campaign Insights
            </h2>
            <h4 className="description">
              Visualize and analyze your campaign performance with detailed
              charts showcasing key metrics and trends.
            </h4>
          </div> */}
        <br />
        {/* <CampaignsWizard /> */}

        <CampaignsGraph />
        <br />
        <br />
        <br />

        <CampaignsTable />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Blockchains />
      </div>
    </motion.main>
  );
}

const CampaignsJoyride = () => {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          {" "}
          Welcome to Blockchain-Ads! Let's explore the main features of the
          platform.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Welcome to Blockchain-Ads",
      // spotlightClicks: false, // Disable spotlight clicks
      // spotlightPadding: 0, // Remove spotlight padding
      // scrollToOffset: 260,
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Launch your first ad campaign here.[Launch Campaign]
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#login-tutorial-3",
      title: "Launch Your First Ad Campaign",
      // scrollToOffset: 260,
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Set up the pixel on the Tracking page and start measuring conversions.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#login-tutorial-6",
      title: "Setting Up Pixel Tracking",
      // scrollToOffset: 260,
      isHidden: window.innerWidth < 576,
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          To switch between your account and the accounts you are a member of,
          just use the dropdown menu.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#login-tutorial-8",
      title: "Switch Accounts",

      isHidden: window.innerWidth < 576,
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;
    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "loginTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("loginTutorial")) return;
    const filteredSteps = defaultSteps.filter((step) => !step.isHidden);
    setSteps((prev) => ({
      ...prev,
      run: true,
      steps: filteredSteps,
    }));
  }, []);

  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
        buttonNext: {},

        buttonBack: {},
        buttonClose: {},
      }}
    />
  );
};
