import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import localData from "../../localData";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter";

export default function AudienceEstimate({ isEstimatedSizeLoading, isUnableToEstimate, result }) {
    const { info } = localData.svgs;
    const { formatLargeNumber } = useFormatter();

    return (
        <div className="audience-estimate">
            <div className="audience-estimate-header">
                <h6 className="audience-estimate-title">Target Audience size</h6>
            </div>
            <hr />
            <div className="audience-estimate-body">
                {isEstimatedSizeLoading ? (
                    "loading..."
                ) : isUnableToEstimate ? (
                    <p className="audience-estimate-unable">Unable to estimate</p>
                ) : (
                    <>
                        <strong className="audience-estimate-value">{formatLargeNumber(result)}</strong>
                        <p className="audience-estimate-description description">
                            Based on your targeting selections, this is the estimated size of your audience
                            over 30 days
                        </p>
                    </>
                )}
            </div>
            <div className="audience-estimate-footer"></div>
        </div>
    );
}
