import React, { useState, useEffect } from "react";
import { motion, warning } from "framer-motion";
import { useGlobalContext } from "../../../context";
import { Field, Button } from "../../../components";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  FormGroup,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionActions,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const { logout } = localData.svgs;
const { logoDark, preloaderBig } = localData.images;

export default function Qualification() {
  const { fade } = useGlobalContext().animations;
  const { _OrganizationAdditionalData, handleSignOut } = useGlobalContext();
  const [qualificationState, setQualificationState] = useState({
    companyName: "",
    _category: "",
    marketingBudget: "",
    websiteURL: "",
    marketingGoal: [],
  });
  const [isAditionalFormLoading, setIsAditionalFormLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherValue, setOtherValue] = useState("");

  const [qualificationErrors, setQualifitcationErrors] = useState({
    companyNameError: false,
    categoryError: false,
    marketingBudgetError: false,
    websiteURLError: false,
    marketingGoal: false,
  });

  const [verticalsOptions, setVerticalsOptions] = useState([
    {
      title: "Decentralized Finance",
      value: "decentralizedFinance",
      propertyName: "decentralizedFinance",
      isSelected: false,
      size: "1200000",
      id: uuidv4(),
    },
    {
      title: "Blockchain Gaming",
      value: "blockchainGaming",
      propertyName: "blockchainGaming",
      isSelected: false,
      size: "2400000",
      id: uuidv4(),
    },
    {
      title: "Betting & Gambling",
      value: "bettingGambling",
      propertyName: "bettingGambling",
      isSelected: false,
      size: "2400000",
      id: uuidv4(),
    },
    {
      title: "Infrastructure",
      value: "infrastructure",
      propertyName: "infrastructure",
      isSelected: false,
      size: "4800000",
      id: uuidv4(),
    },
    {
      title: "CeFi/CEX",
      propertyName: "CeFiCEX",
      value: "CeFiCEX",
      isSelected: false,
      size: "2400000",
      id: uuidv4(),
    },
    {
      title: "TradeFi",
      propertyName: "tradeFi",
      value: "tradeFi",
      isSelected: false,
      size: "8000000",
      id: uuidv4(),
    },
    {
      title: "DePin/AI",
      value: "dePinAI",
      propertyName: "dePinAI",
      size: "0",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Meme/NFT",
      value: "memeNFT",
      propertyName: "memeNFT",
      size: "0",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Adult",
      value: "adult",
      propertyName: "adult",
      size: "0",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Other",
      value: "other",
      propertyName: "value",
      isSelected: true,
      size: "",
      id: uuidv4(),
    },
  ]);

  const [marketingBudgetOptions, setMarketingBudgetOptions] = useState([
    { title: "$10k-$50k", isSelected: false, id: uuidv4() },
    { title: "$50k-$100k", isSelected: false, id: uuidv4() },
    { title: "Above $100k ", isSelected: false, id: uuidv4() },
  ]);

  const [marketingGoalOptions, setMarketingGoalOptions] = useState([
    {
      title: "Awareness - Mass Reach",
      value: "awarenessMassReach",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "User Acquisition",
      value: "userAcquisition",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "App Downloads",
      value: "appDownloads",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Signups",
      value: "signUps",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Token Public Launch",
      value: "tokenPublicLaunch",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Token Private Launch (Pre-sale)",
      value: "tokenPrivateLaunch",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Increase token/coin holders",
      value: "increaseTokenHolders",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "EcoSystem growth",
      value: "ecoSystemGrowth",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "First time deposits",
      value: "firstTimeDeposits",
      isSelected: false,
      id: uuidv4(),
    },
    { title: "other", value: "other", isSelected: false, id: uuidv4() },
  ]);

  function checkIsValidUrl(url) {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setQualificationState({ ...qualificationState, [name]: value });
    setQualifitcationErrors((prevState) => ({
      ...prevState,
      [`${name}Error`]: false,
    }));
  };

  const handleClick = () => {
    let hasError = false;

    if (!qualificationState.companyName.length) {
      setQualifitcationErrors((prevState) => ({
        ...prevState,
        companyNameError: true,
      }));
      hasError = true;
    }

    if (!qualificationState.marketingBudget.length) {
      setQualifitcationErrors((prevState) => ({
        ...prevState,
        marketingBudgetError: true,
      }));
      hasError = true;
    }

    if (!qualificationState._category.length) {
      setQualifitcationErrors((prevState) => ({
        ...prevState,
        categoryError: true,
      }));
      hasError = true;
    }

    if (!qualificationState.marketingGoal.length) {
      setQualifitcationErrors((prevState) => ({
        ...prevState,
        marketingGoal: true,
      }));
      hasError = true;
    }

    if (!qualificationState.websiteURL.length) {
      setQualifitcationErrors((prevState) => ({
        ...prevState,
        websiteURLError: true,
      }));
      hasError = true;
    }

    if (hasError) {
      let elementId = "companyName";
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    }

    _OrganizationAdditionalData({
      ...qualificationState,
      qualificationState,
      setQualificationState,
      setIsLoading: setIsAditionalFormLoading,
    });
  };

  useEffect(() => {
    setQualificationState((prev) => ({
      ...prev,
      _category: otherValue,
    }));
  }, [otherValue, setOtherValue]);

  const [marketingGoalOther, setMarketingGoalOther] = useState("other");

  const handleMarketingGoal = (title) => {
    let options = [...marketingGoalOptions];
    options = options.map((item) => {
      if (item.title !== title) return { ...item };
      return {
        ...item,
        isSelected: !item.isSelected,
        value: item.title === "other" ? marketingGoalOther : item.value,
      };
    });
    setMarketingGoalOptions(options);
    setQualificationState({
      ...qualificationState,
      marketingGoal: options
        .filter((item) => item.isSelected)
        .map((item) =>
          item.title === "other" ? marketingGoalOther : item.value
        ),
    });
    setQualifitcationErrors((prevState) => ({
      ...prevState,
      marketingGoal: false,
    }));
  };

  const handleSelectChange = (event) => {
    const selectedItem = marketingBudgetOptions.find(
      (item) => item.title === event.target.value
    );
    setQualificationState((prev) => ({
      ...prev,
      marketingBudget: selectedItem ? selectedItem.title : "",
    }));
    setQualifitcationErrors((prev) => ({
      ...prev,
      marketingBudgetError: false,
    }));
  };

  return (
    <motion.main {...fade} className="main qualification-page">
      <div className="container">
        <img className="logo" src={logoDark} />
        <section className="qualification">
          <div>
            <TextField
              type="text"
              label={"Name"}
              variant="outlined"
              size="small"
              name="companyName"
              style={{
                borderColor: "red !important",
              }}
              error={qualificationErrors.companyNameError}
              value={qualificationState.companyName}
              onChange={handleOnChange}
              required={true}
              fullWidth
              helperText={
                qualificationErrors.companyNameError && (
                  <span className="custom-error-message">
                    <ReportProblemIcon />
                    This field is required.
                  </span>
                )
              }
            />
            <br />
            <br />
            <TextField
              type="text"
              label="URL of the brand you want to advertise"
              variant="outlined"
              size="small"
              name="websiteURL"
              error={qualificationErrors.websiteURLError}
              value={qualificationState.websiteURL}
              onChange={handleOnChange}
              required={true}
              fullWidth
              helperText={
                qualificationErrors.websiteURLError ? (
                  <span className="custom-error-message">
                    <ReportProblemIcon />
                    This field is required.
                  </span>
                ) : (
                  ""
                )
              }
            />
            <p className="error-message">
              {qualificationState.websiteURL !== "" &&
              !checkIsValidUrl(qualificationState.websiteURL)
                ? `Invalid URL. Please enter a valid URL. e.g.
                                <br /> https://johndoe.com`
                : ""}
            </p>
            <br />
            <FormControl
              variant="outlined"
              color="primary"
              size="small"
              fullWidth
              error={qualificationErrors.marketingBudgetError}
            >
              <InputLabel id="marketing-budget-label">
                Advertising Budget *
              </InputLabel>
              <Select
                labelId="marketing-budget-label"
                value={qualificationState.marketingBudget}
                onChange={handleSelectChange}
                label="Advertising Budget *"
                style={{
                  borderColor: qualificationErrors.marketingBudgetError
                    ? "red"
                    : "",
                }}
                size="small"
              >
                {marketingBudgetOptions.map((option) => (
                  <MenuItem key={option.id} value={option.title}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
              {qualificationErrors.marketingBudgetError && (
                <FormHelperText>Please select a valid budget.</FormHelperText>
              )}
            </FormControl>
            <br />
            <br />
            <Accordion
              sx={{
                borderRadius: "10px",
                border: `1px solid ${
                  qualificationErrors.categoryError ? "red" : "#0000003b"
                }`,
                boxShadow: "none",
                "::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ maxHeight: "12px !important" }}
              >
                <FormLabel component="legend">
                  What niche is the brand in?*
                </FormLabel>
              </AccordionSummary>
              <AccordionActions
                sx={{
                  justifyContent: "flex-start",
                  padding: "1rem",
                  paddingTop: "0",
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedOption}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSelectedOption(value);

                      setQualificationState((prev) => ({
                        ...prev,
                        _category: value === "other" ? otherValue : value,
                      }));
                      setQualifitcationErrors((prevState) => ({
                        ...prevState,
                        categoryError: false,
                      }));
                    }}
                  >
                    {verticalsOptions.map((option) =>
                      option.value === "other" ? (
                        <Grid
                          container
                          alignItems="center"
                          key={option.value}
                          spacing={1}
                        >
                          <Grid item>
                            <FormControlLabel
                              value={option.value}
                              control={
                                <Radio
                                  onClick={(e) => setOtherValue("other")}
                                />
                              }
                              label={option.title}
                            />
                          </Grid>
                          <Grid item xs>
                            <TextField
                              placeholder="Specify Other"
                              value={otherValue}
                              onChange={(e) => setOtherValue(e.target.value)}
                              fullWidth
                              size="small"
                              disabled={selectedOption !== "other"}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.title}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              </AccordionActions>
            </Accordion>
            <br />
            <Accordion
              sx={{
                borderRadius: "10px !important",
                border: `1px solid ${
                  qualificationErrors.marketingGoal ? "red" : "#0000003b"
                }`,
                boxShadow: "none",
                "::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}         sx={{
          minHeight: "10px !important", // Adjust this for the height
          "&.Mui-expanded": {
            minHeight: "10px !importnat", // Keep the same height when expanded
          },
        }}>
                <FormLabel component="legend">
                  What is the Goal for your company/brand? *
                </FormLabel>
              </AccordionSummary>
              <AccordionActions
                sx={{
                  justifyContent: "flex-start",
                  padding: "1rem",
                  paddingTop: "0",
                }}
              >
                <FormControl component="fieldset">
                  <FormGroup>
                    {marketingGoalOptions.map((option, index) =>
                      option.value === "other" ? (
                        <Grid
                          container
                          alignItems="center"
                          key={option.value}
                          spacing={1}
                        >
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={index}
                                  onClick={() =>
                                    handleMarketingGoal(option.title)
                                  }
                                  checked={option.isSelected}
                                />
                              }
                              label={option.title}
                            />
                          </Grid>
                          <Grid item xs>
                            <TextField
                              placeholder="Other"
                              fullWidth
                              size="small"
                              required={true}
                              name="other"
                              value={marketingGoalOther}
                              onChange={(e) =>
                                setMarketingGoalOther(e.target.value)
                              }
                              onBlur={handleMarketingGoal}
                              disabled={!option.isSelected}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Checkbox
                              key={index}
                              onClick={() => handleMarketingGoal(option.title)}
                              checked={option.isSelected}
                            />
                          }
                          label={option.title}
                        />
                      )
                    )}
                  </FormGroup>
                </FormControl>
              </AccordionActions>
            </Accordion>
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="button"
                className="submit-btn"
                name="Log out"
                color="danger"
                // size="sm"
                onClick={handleSignOut}
                startIcon={logout}
                icon={
                  false ? (
                    <span className="endIcon">
                      <img src={preloaderBig} />
                    </span>
                  ) : null
                }
                disabled={false}
              />
              <Button
                type="button"
                className="submit-btn"
                name="Complete"
                color="success"
                variant="contained"
                size="sm"
                onClick={handleClick}
                // disabled={isAditionalFormLoading || isBtnDisabled}
              />
            </div>
          </div>
        </section>
        <p className="copyright">
          ©Blockchain-Ads. All rights reserved.
          <Link
            to="/privacy-policy"
            underline="hover"
            className="link link-primary"
          >
            {" "}
            Privacy Policy
          </Link>
        </p>
      </div>
    </motion.main>
  );
}
