import React from "react";
import localData from "../../../localData";

const { notFound } = localData.images;

export default function CustomOptionCard({
    title = "",
    description = "",
    icon = notFound,
    onClick=()=>{}
}) {
    return (
        <div className="card option-card" onClick={onClick}>
            <div className="card-icon">
                {icon}
            </div>
            <div className="wrapper">
                <h4 className="card-title">{title}</h4>
                <p className="card-description">{description}</p>
            </div>
        </div>
    );
}
