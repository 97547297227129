import React, { useState, useEffect, useRef } from "react";
import { Button, Carousel } from "../";
import localData from "../../localData";
import { useStateContext } from "../../stateContext";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../context";
import { useLocation, useNavigate } from "react-router-dom";

function TabTitleItem({
    index,
    title,
    activeTab,
    setActiveTab,
    activeTabRef,
    startIcon,
    startImageIcon,
    id,
    tabClickable = true,
    tabMarked = true,
    tabDisabled = false,
    isTitleHidden = false,
}) {
    return (
        <li
            key={index}
            id={id}
            // className={`nav-item ${activeTab === index ? "active" : ""}`}
            className={`nav-item ${tabMarked && activeTab !== index ? "marked" : ""} ${
                tabDisabled ? "disabled" : ""
            }`}
            ref={activeTab === index ? activeTabRef : null}
            style={isTitleHidden ? { display: "none" } : {}}
        >
            <Button
                style={{ pointerEvents: tabClickable ? "auto" : "none" }}
                variant="text"
                className={`nav-link ${activeTab === index ? "active" : ""}`}
                onClick={() => setActiveTab(index)}
                name={title}
                startIcon={startIcon}
                startImageIcon={startImageIcon}
                color="dark"
            />
        </li>
    );
}



export default function Tabs({
    tabs,
    defaultTab = 0,
    className,
    swiper,
    indicatorIcon,
    tabsProps,
    callback = () => {},
    isLoading = false,
    loadingMessage = "",
    hideTabs = false,
    contentId = "",
    horizontal = false,
    tabsLocation = "",
    activeTabInstalPixel = ""
}) {
    const history = useNavigate();
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const initialTab = tabsLocation === "profile" ? parseInt(urlParams.get("tab")) || defaultTab : defaultTab;

    const [activeTab, setActiveTab] = useState(initialTab);
    const [isShown, setIsShown] = useState(initialTab);
    const [style, setStyle] = useState(null);
    const activeTabRef = useRef(null);
    const [activeStepTitle, setActiveStepTitle] = useState(tabs[activeTab.title])
    const { arrowRight } = localData.svgs;

    useEffect(() => {
        callback(tabs[activeTab].title);
        setActiveStepTitle(tabs[activeTab].title)
        setIsShown(activeTab);

        if (tabsLocation === "profile") {
            const newParams = new URLSearchParams(location.search);
            newParams.set("tab", activeTab);
            history({ search: newParams.toString() })
        }

        if (!horizontal) {
            setStyle({
                ...style,
                top: activeTabRef.current?.offsetTop + "px",
            });
        } else {
            setStyle({
                ...style,
                left: activeTabRef.current?.offsetLeft + "px",
            });
        }
    }, [activeTab]);

    return (
        <div className={`tabs shadow ${className} ${swiper ? "swiper-tabs" : ""}`}>
            <ul
                className={`nav nav-tabs ${isLoading ? "on-hold" : ""}`}
                style={hideTabs ? { opacity: 0, pointerEvents: "none" } : {}}
            >
                {swiper ? (
                    <Carousel
                        items={tabs}
                        Card={TabTitleItem}
                        cardOptions={{ activeTab, setActiveTab, activeTabRef }}
                        options={{
                            slidesPerView: "auto",
                            spaceBetween: 5,
                            breakpoints: null,
                            loop: false,
                            autoplay: false,
                            speed: 500,
                        }}
                    />
                ) : (
                    tabs.map((item, index) => (
                        <TabTitleItem
                            key={index}
                            {...{ ...item, index, activeTab, setActiveTab, activeTabRef }}
                        />
                    ))
                )}

                {!swiper && (
                    <div className="indicator dark-mode-style" style={style}>
                        {indicatorIcon || arrowRight}
                    </div>
                )}
                {activeStepTitle === "Pixel" && (
                    <p className="install-pixel-tab-description text-style-1">
                        Install with Pixel code: Copy paste our pixel’s code snippet before the {'</head>'} HTML tag on all pages of your website.
                    </p>
                )}


            </ul>
            {isLoading && <div className="loading-message">{loadingMessage}</div>}
            <div className="divider"></div>
            <div className="tab-content" id={contentId || `tab-content-${uuidv4()}`}>
                {tabs.map(({ content }, index) => (
                    <div
                        key={index}
                        className={`tab-pane fade ${isShown === index ? "show" : ""} ${
                            activeTab === index ? "active" : ""
                        }`}
                    >
                        {activeStepTitle === "Google Tag Manager" &&  <p className="install-pixel-tab-description text-style-1">Install with Tag Manager: Copy paste our pixel’s code snippet into a Custom HTML Tag that fires on all <b>pageviews trigger</b>.</p>}
                        {activeStepTitle === "Tracking Pixel" &&  <p className="install-pixel-tab-description text-style-1">{`Copy & paste our pixel script before the </body> tag of all pages of your website.`}</p>}
                        {React.cloneElement(content, { setActiveTab, activeTab, ...tabsProps })}
                    </div>
                ))}
            </div>
        </div>
    );
}
