import React, { useState, useEffect } from "react";
import { Drawer, Button, Tabs } from "../../../components";
import localData from "../../../localData";
import InstallPixelTab from "./tracking-tabs/install-pixel-tab/InstallPixelTab";
import CreateConversionTab from "./tracking-tabs/create-conversion-tab/CreateConversionTab";
import { useGlobalContext } from "../../../context";

const { arrowLeft, plus } = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function TrackingDrawer({name = "Setup Tracking"}) {
    const { requestedData, trackingState } = useGlobalContext();

    const {
        displayInstallPixel,
        setDisplayInstallPixel,
        setIsEditingMode,
        setDefaultTab,
        setEditingTagId,
        resetState,
    } = trackingState;

    return (
        <Drawer
            display={displayInstallPixel}
            setDisplay={setDisplayInstallPixel}
            disabled={!requestedData.userProfileData.currentOrganization.id}
            toggler={
                <Button
                id="tracking-tutorial-2"
                    name={name}
                    variant="contained"
                    // startIcon={question}
                    color="success"
                    startIcon={plus}
                    onClick={() => {
                        setIsEditingMode(false);
                        setEditingTagId("");
                        setDefaultTab(0);
                        resetState();
                    }}
                />
            }
            className="drawer-right tracking-drawer"
            callback={() => {}}
            Component={DrawerComponent}
        />
    );
}

const DrawerComponent = ({ callbackFromParent }) => {
    const { requestedData, trackingState } = useGlobalContext();

    const { defaultTab, setDefaultTab, isEditingMode } = trackingState;
    return (
        <div className={`${isEditingMode ? "editing-mode" : ""}`}>
            <h2 className="display-2">Blockchain-Ads Tracking Script</h2>
            {/* <p className="text-style-1">
                Set up conversion tracking with Blockchain-Ads to measure specific user actions on your
                website.
            </p> */}
            {/* <div>step 1 ----------- step 2 ---------- step 3</div> */}

            <br />
            <Tabs
                defaultTab={defaultTab}
                tabsProps={{ callbackFromParent }}
                tabs={trackingTabs}
                title="Setup Tracking"
                // indicatorIcon={question}
                className="tracking-tabs"
            />
        </div>
    );
};

const ConfirmationTab = ({ setActiveTab }) => {
    return (
        <div>
            confirmation tab
            <br />
            <br />
            <br />
            <Button
                name="back"
                color="pale-dark"
                startIcon={arrowLeft}
                size="sm"
                onClick={() => setActiveTab(1)}
            />
        </div>
    );
};

const trackingTabs = [
    {
        title: "--install Pixel (step 1)",
        content: <InstallPixelTab />,
        id: "install-pixel-tab-eifoei2983yr23",
    },
    {
        title: "--create conversion (step 2)",
        content: <CreateConversionTab />,
        id: "create-conversion-tab-jfldsjfl23",
    },
    {
        title: "--confirmation (step 3)",
        content: <ConfirmationTab />,
        id: "confirmation-tab-jfldsjfl23",
    },
];
