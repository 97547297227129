import React, { useState, useEffect, useRef } from "react";
import { TableSkeleton, Select, Button, Popup, Pagination } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { useStateContext } from "../../../../stateContext";
import useFormatter from "../../../../hooks/useFormatter";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";

export default function Ads() {
    const {
        requestedData,
        isLoading,
        requestedData: {
            campaignStatistics: { tab },
        },
        handleOnClick, getSortIcon
    } = useGlobalContext();
    const { textPreloader } = localData.images;

    return (
        <div className="zones-wrapper">
            <div className="scroll shadow bg-white" style={{ padding: 0 }}>
                {isLoading.campaignStatisticsLoading ? (
                    <TableSkeleton
                        message={`Processing data on server - please wait... `}
                        icon={textPreloader}
                    />
                ) : (
                    <table id="ads-table" className="table ads-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-hash text-start">Banner</th>
                                <th
                                    className="table-th-age text-center clickable"
                                    onClick={() => handleOnClick("impressions")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                        className="with-sort"
                                    >
                                        Impressions {getSortIcon("impressions")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-from text-center clickable"
                                    onClick={() => handleOnClick("clicks")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                        className="with-sort"
                                    >
                                        Clicks {getSortIcon("clicks")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("ctr")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ctr"
                                        data-tooltip-content={` How often your ads are clicked when viewed by Blockchain-Ads users.`}
                                        className="with-sort"
                                    >
                                        CTR {getSortIcon("ctr")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("spent")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={` How much budget has been spent.`}
                                        className="with-sort"
                                    >
                                        Ad Spend {getSortIcon("spent")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("cpc")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-cpc"
                                        data-tooltip-content={`How much you pay for each click.`}
                                        className="with-sort"
                                    >
                                        CPC {getSortIcon("cpc")}
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!requestedData.campaignStatistics.items.length || tab.toLowerCase() !== "ads" ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                requestedData.campaignStatistics.items.map((item, index) => (
                                    <Row key={index} item={item} index={index} />
                                ))
                            )}
                        </tbody>
                    </table>
                )}

                {!isLoading.campaignStatisticsLoading && !requestedData.campaignStatistics.items.length && (
                    <div className="no-data-cover"></div>
                )}
            </div>
            <Tooltip id="tooltip-campaign-image" className="custom-tooltip image-tooltip" />
        </div>
    );
}

const Row = ({ item, index }) => {
    const { requestedData } = useGlobalContext();
    const { eye, eyeSlash } = localData.svgs;
    const { formatNumber, formatCurrency } = useFormatter();

    const getImage = (campaign_id, banner_id) => {
        const campaign = requestedData.campaignData.currentOrganizationCampaigns.find(
            (campaign) => campaign.id == campaign_id
        );

        if (!campaign) return "";
        const creative = campaign.creatives.find((item) => item.id == banner_id)?.image;

        return creative;
    };

    return (
        <>
            <tr className="table-row">
                <td className="ads-td-image  text-start">
                    <div
                        className="content"
                        data-tooltip-id="tooltip-campaign-image"
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <div className="image-wrapper">
                                <img src={getImage(item.campaign_id, item.banner_id)} />
                            </div>
                        )}
                        data-tooltip-float={true}
                    >
                        <img src={getImage(item.campaign_id, item.banner_id)} />
                    </div>
                </td>
                <td className="table-td-age text-center">{formatNumber(item.impressions)}</td>
                <td className="table-td-from text-center">{formatNumber(item.clicks)}</td>

                <td className="table-td-quantity text-center">{item.ctr?.toFixed(2)}%</td>
                <td className="table-td-quantity text-center">{formatCurrency(item.spent, 2)}</td>
                <td className="table-td-cpc text-center">{formatCurrency(item.cpc, 2)}</td>
            </tr>
        </>
    );
};
