import React from "react";
import localData from "../../../localData";

export default function AdvertisingPolicy() {
    const { circleQuestion } = localData.svgs;

    return (
        // <div className="advertising-policy">
        //     <h2 className="display-2 advertising-policy-title">Advertising Policy</h2>

        //     <div className="row question">
        //         <div className="question-icon">{circleQuestion}</div>
        //         <div className="wrapper">
        //             <h5 className="display-6 question-title">Preamble</h5>
        //             <p className="question-text">
        //                 These General Terms and Conditions of Business for the Placement of Advertising Media
        //                 (hereinafter “Terms“, “T&C” and/or “Terms and Conditions”)) shall – in connection with
        //                 the individual contract or separately – form the relationship between us, the
        //                 BlockchainAds Labs LLC, Singapore (“Blockchain-ads“, “we” or “us“), and our Customers
        //                 (hereinafter “Customers” or “Advertisers”). Unless otherwise explicitly agreed, these
        //                 Terms shall be exclusively applied to the contract. General contractual or commercial
        //                 conditions of the Customer shall have no validity. This shall also apply if these
        //                 conditions are not explicitly contradicted.
        //             </p>
        //         </div>
        //     </div>

        //     <div className="row question">
        //         <div className="question-icon">{circleQuestion}</div>
        //         <div className="wrapper">
        //             <h5 className="display-6 question-title">1. Introduction</h5>
        //             <p className="question-text">
        //                 1.1Before using the website Blockchain-ads, please read in full and carefully the
        //                 provisions of these Terms and Conditions. 1.2 If you do not agree with the provisions
        //                 of the Terms and Conditions, including any updates thereof, please stop using the
        //                 Website immediately. 1.3 Use of the Blockchain-ads Website and Services provided
        //                 through the platform represents the express agreement to accept these Terms and
        //                 Conditions (including any updates thereof), even in the event of the User’s failure to
        //                 review the applicable Terms and Conditions. 1.4 BY USING THE Blockchain-Ads PLATFORM
        //                 AND ANY OF THE SERVICES, YOU EXPRESSLY AGREE TO THE APPLICABILITY OF THE CLAUSES
        //                 SPECIFIED IN THIS TERMS AND CONDITIONS AND OTHER RESPECTIVE DOCUMENTS PUBLISHED ON
        //                 THIS SITE REGULATING THE SERVICES.
        //             </p>
        //         </div>
        //     </div>

        //     <div className="row question">
        //         <div className="question-icon">{circleQuestion}</div>
        //         <div className="wrapper">
        //             <h5 className="display-6 question-title">2. General Provisions</h5>
        //             <p className="question-text">
        //                 2.1 The present Terms and Conditions specify the conditions of use of the
        //                 blockchain-ads website (“Website”, “Platform” or “Blockchain-ads Platform”) and the
        //                 services offered through it by the Company (“Services”), and represent a legally
        //                 binding agreement (the “Agreement”) between BlockchainAds Labs LLC, Singapore, a
        //                 company incorporated under the laws of Singapore, registered number 21173533
        //                 (hereinafter referred to as the “Company”) and users of the Blockchain-Ads Platform
        //                 (“Users”), respectively: Individuals (at least 18 years old) or legal entities who
        //                 wish to promote their website, products and/or services through the Blockchain-ads
        //                 Platform or to have their press releases published on cryptocurrency-related websites
        //                 (“Advertisers”); Individuals (at least 18 years old) or legal entities who wish to
        //                 commercialize all or part of the advertising space on websites owned and/or managed by
        //                 them (“Publishers”); Individuals who view the Website but do not register on the
        //                 Website by creating a User account (“Visitors”).
        //             </p>
        //         </div>
        //     </div>
        // </div>

        <div className="advertising-policy">
            <h2 className="display-2 advertising-policy-title">Advertising Policy</h2>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">Preamble</h5>
                    <p className="question-text">
                        These General Terms and Conditions of Business for the Placement of Advertising Media
                        (hereinafter “Terms“, “T&amp;C” and/or “Terms and Conditions”)) shall – in connection
                        with the individual contract or separately – form the relationship between us, the
                        BlockchainAds Labs LLC, Singapore (“Blockchain-ads“, “we” or “us“), and our Customers
                        (hereinafter “Customers” or “Advertisers”). Unless otherwise explicitly agreed, these
                        Terms shall be exclusively applied to the contract. General contractual or commercial
                        conditions of the Customer shall have no validity. This shall also apply if these
                        conditions are not explicitly contradicted.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">1. Introduction</h5>
                    <p className="question-text">
                        1.1Before using the website{" "}
                        <a _ngcontent-ipv-c179="" href="http://www.blockchain-ads.com/">
                            Blockchain-ads
                        </a>
                        , please read in full and carefully the provisions of these Terms and Conditions. 1.2
                        If you do not agree with the provisions of the Terms and Conditions, including any
                        updates thereof, please stop using the Website immediately. 1.3 Use of the
                        Blockchain-ads Website and Services provided through the platform represents the
                        express agreement to accept these Terms and Conditions (including any updates
                        thereof), even in the event of the User’s failure to review the applicable Terms and
                        Conditions. 1.4 BY USING THE Blockchain-Ads PLATFORM AND ANY OF THE SERVICES, YOU
                        EXPRESSLY AGREE TO THE APPLICABILITY OF THE CLAUSES SPECIFIED IN THIS TERMS AND
                        CONDITIONS AND OTHER RESPECTIVE DOCUMENTS PUBLISHED ON THIS SITE REGULATING THE
                        SERVICES.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">2. General Provisions </h5>
                    <p className="question-text">
                        2.1 The present Terms and Conditions specify the conditions of use of the
                        blockchain-ads website (“Website”, “Platform” or “Blockchain-ads Platform”) and the
                        services offered through it by the Company (“Services”), and represent a legally
                        binding agreement (the “Agreement”) between BlockchainAds Labs LLC, Singapore, a
                        company incorporated under the laws of Singapore, registered number 21173533
                        (hereinafter referred to as the “Company”) and users of the Blockchain-Ads Platform
                        (“Users”), respectively: Individuals (at least 18 years old) or legal entities who
                        wish to promote their website, products and/or services through the Blockchain-ads
                        Platform or to have their press releases published on cryptocurrency-related websites
                        (“Advertisers”); Individuals (at least 18 years old) or legal entities who wish to
                        commercialize all or part of the advertising space on websites owned and/or managed by
                        them (“Publishers”); Individuals who view the Website but do not register on the
                        Website by creating a User account (“Visitors”).
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">3. Terms and Definitions </h5>
                    <p className="question-text">
                        3.1 These Terms constitute a legally binding agreement between you and the
                        Blockchain-ads platform. 3.2 These Terms apply to any and all Services, information,
                        texts, and other products, offered on the Website by the Company. 3.3 You cannot use
                        the Website and create the Account if you have not read, understood, and accepted all
                        the provisions of these Terms.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">
                        4. Description of the Services and joining the Services{" "}
                    </h5>
                    <p className="question-text">
                        4.1 Blockchain-ads is an advertising network focused on connecting Advertisers and
                        Publishers through such services globally. The Services provided by the Company
                        through the Website and allow Advertisers to promote their website, services and/or
                        products or to have their creatives published on Publishers’ websites, by buying such
                        services from the Company. 4.2 The Company offers Advertisers the opportunity to set
                        up and configure their advertising campaign (e.g. Advertisers can choose the type of
                        banner they want to use or the country targeted by the advertisement). “Campaign”
                        means the order issued by an Advertiser via the Blockchain-ads Website, defined by a
                        number of settings which are necessary for the broadcasting of an advertisement or
                        sponsored content (press releases and various articles) on the Publishers’ websites,
                        intended at promoting Advertisers’ projects. 4.3 Advertisers will have access to the
                        Services only after the creation of a User account on the Website. 4.4 At the
                        Advertisers’ request, our Company’s team may create unique creatives according to
                        Advertisers’ specifications and deliver them to the Publishers. 4.5 Other services
                        that may be subsequently implemented on the Website are subject to this set of Terms
                        and Conditions, unless they have specific terms and conditions that will be formulated
                        separately. 4.6 All advertising Campaigns on the Blockchain-ads Platform are served,
                        tracked, and reported by the Company. The data generated by a Campaign will only be
                        available for 6 (six) months. After the expiration of this period, the data will be
                        deleted. 4.7 In order to have access to the Services, you have to create a User
                    account on the Blockchain-ads Platform following the steps communicated by the
                        Company. 4.8 Any changes to the data regarding the User (Advertiser) account, billing
                        address, the email address used to log in, etc., will be made through the user
                        profile. 4.9 Blockchain-ads reserves the right to verify the information provided by
                        the User upon registration and to also request supporting documents if any third
                        parties (such as, without limitation, state authorities, banks, payment processors) so
                        request for grounded reasons. 4.10 All details and information provided by the User
                        shall be correct. If the information provided during the creation of the user account
                        changes subsequently, the User is obliged to promptly update the respective
                        information contained in the User’s account. 4.11 All services provided to Advertisers
                        and implemented on the Website are subject to this set of Terms and Conditions, unless
                        they have specific terms and conditions that will be formulated on case-by case basis.
                        4.12 By creating a User Account on the Blockchain-ads Platform, the User’s access to
                        the Services provided by the Company is ensured, with the account becoming functional
                        after the User is approved as an Advertiser. Moreover, the User account shall keep the
                        record of all the amounts paid by the Users to Blockchain-Ads for the Services
                        provided to them. For the avoidance of any doubt, the information on the value of such
                        amounts is reflected in the User account for information purposes only, providing the
                        Advertiser with the record of the amounts paid to the Company at any given time.
                        Depending on the operations made by the User, the information in the User account
                        shall be appropriately updated. 4.13 By creating account, the Advertiser expressly
                        declares and warrants that: Uses the Website is used for the specific purposes of its
                        commercial activity, which it is authorized to carry out; All information provided is
                        complete, true, and accurate; Understands and agrees that, in addition to the
                        provisions of the Terms and Conditions certain Services may be governed by specific
                        rules. Any such rules will be published separately; Understands that they are solely
                        responsible for the security of the account and account password and will not allow
                        other persons to access the account. In case of any breach or suspicion of breach of
                        security of the User’s account, the User must report it immediately to Blockchain-ads.
                        Blockchain-ads will not be held liable for any damage caused as a result of the breach
                        of account security due to the conduct of the User or any person accessing and/or
                        using the User account, including but not limited to situations when: computer systems
                        used are infected with viruses or other malware, or the device on which the User is
                        logged in is accessed by anyone else, or the security of the email account associated
                        with the User’s account is in any way compromised. 4.14 Blockchain-ads reserves the
                        right to delete user accounts that have been inactive for a period of 6 (six) months.
                        4.15 Without a valid and confirmed email address, the User account cannot function
                        optimally. Confirmation of the email address also prevents unauthorized persons from
                        using the email address. 4.16 Users shall not use automated means of traffic
                        generation, including scripts, robot, bot, spider, crawler and /or any computer
                        applications/programs that may deceive or simulate certain activities or statuses on
                        the Website, or, in any way, exploit certain functions or vulnerabilities of the
                        Website in order to obtain advantages, for themselves or for other Users, regarding
                        the use of the Services offered through the Website. 4.17 Blockchain-ads reserves the
                        right to restrict or exclude Users’ access to the Website, as well as to delete or
                        restrict the user account, within the limits of the law, if it considers that based on
                        the User’s conduct or activity, access and existence of the account may harm in any
                        way Blockchain-ads or other Users.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">5. Website Acceptance and Eligibility </h5>
                    <p className="question-text">
                        5.1 The Company does not accept any form of advertisement that can hurt or is
                        inappropriate for the general audience in particular content provided by the
                        Advertisers on their websites for the purposes of this T&amp;C. In order for an
                        advertising Campaign to be accepted, the Advertisers’ landing page must comply with
                        the present Terms and Conditions. 5.2 If you act as an advertising agent for another
                        individual or entity, then you represent and warrant that: (a) you are authorized to,
                        and do, bind that individual or entity to the Terms and Conditions; and (b) all of
                        your actions in connection with these Terms and Conditions are and will be within the
                        scope of the agency relationship between you and that individual or entity, and in
                        accordance with any applicable legal and fiduciary duties. 5.3 When acting as an agent
                        on behalf of an advertiser, we may request that you provide written confirmation of
                        such and/or evidence of the advertiser’s consent. 5.4 The general criteria on which
                        the approval of a Campaign relies are as follows: the website of the promoted project
                        must be functional, accessible, contain all the details in such page, such details
                        must be conclusive and valid, and the buttons must be functional; the banners used in
                        the Campaign must be professional and reflect the same ideas as the promoted project;
                        in the case of investment websites, the risk to which the user is exposed must be
                        mentioned on the page; websites that sell mining equipment must prove that they are an
                        authorized reseller or the manufacturer of the equipment; 5.5 websites that are based
                        on a token must have: sufficient liquidity to allow currency trading; the token
                        contract must be available for analysis by potential buyers and/or interested parties;
                        the project’s team must be displayed on the website, and their information must be
                        accurate and verifiable; the website must not present integrations or the use of
                        various services as partnerships; the information presented on the website must be
                        accurate and true, without misleading potential buyers; the Company reserves the right
                        to reject the campaign/campaigns of a project based on the token, even if the
                        conditions mentioned above are met. 5.6 The Company reserves the right to deny any
                        website from joining its advertising Campaign, without providing any justification,
                        including but not limited to sites/advertising aimed at promoting sites: that violate
                        the laws, regulations, or any other applicable legal requirements; containing or
                        linking to any form of illegal/inappropriate or violent content or sites with illegal,
                        false, or deceptive investment advice and money-making opportunities; promoting any
                        type of hatemongering (i.e. racial, political, ethnic, religious, gender-based,
                        sexuality-based, personal, etc.); that participate in or transmit spam using any kind
                        of online means; that ask users for clicks, that incentivize traffic, or
                        direct/redirect them to any fraudulent activity that would bring the owner more
                        earnings from our network; that are using free domain names; with no original content
                        or generated on platforms like WordPress or Blogspot; represent URL Shorteners;
                        contain: any popup/popunder or page blocker; scripts that alter the user’s browser
                        settings; any frame-breaking codes; multiple alert boxes or alert boxes with “Close”
                        buttons that cannot be seen by users; that are automatic/manual traffic exchanges;
                        that download, send, transmit or otherwise post and/or distribute any materials
                        containing viruses or other computer codes, files or programs designed to breach,
                        destroy or limit the operation of any computer or telecommunication equipment or
                        software; that, after they were reviewed by our team, were not deemed suitable for our
                        publisher program. 5.7 The Company shall make its best efforts for the articles
                        purchased through the Blockchain-Ads Marketplace Platform to remain live on the
                        Publisher’s website(s) (as long as the Company has a contractual relationship with the
                        Publisher and the Advertisers are paying the requested fees). However, the article may
                        be removed in the cases of, but not limited to: fraud, the inactivity of the project,
                        the change of the Advertiser’s domain of activity, or any other circumstance that
                        would make the article unsuitable for the promotion of the Advertiser. Such facts
                        shall be proved with concrete evidence by the Publisher. 5.8 If the Publisher deletes
                        an article acquired via the Blockchain-ads Platform under the Clause 5.7, the
                        Advertiser will not be entitled to a refund of the amounts paid for the purchase of
                        such article. The Company will not be responsible in any way for this action.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">6. Price of the Services and Referrals </h5>
                    <p className="question-text">
                        6.1In order to start using the Services, Advertisers have to pay to the Company, in
                        advance, a minimum amount of 0.0000205 BTC (CPC campaign) and 0.000025 BTC (CPM
                        campaign). The amounts paid may be spent on any campaign type offered by the Company.
                        For the amounts paid in advance to the Company by the Advertiser (which represent the
                        counter-value of the Services provided by the Company through the Platform), the
                        Company will issue relevant invoices. Advertisers’ active campaigns will receive
                        traffic as long as the price for the supply of these Campaigns is paid in advance. For
                        information purposes, the value of the amounts paid to the Company by the Advertiser
                        is recorded in the User account of the Advertiser. The value standing to the balance
                        of the User account shall be updated to the extent that the Company provides Services
                        to the Advertiser, as per the latter’s instructions. The balance of the User account
                        shall indicate at any time the value of the amounts paid in advance by the Advertiser
                        and in consideration for which no Services have been provided yet by the Company. If
                        the Advertiser’s User account balance becomes zero, i.e. there are no further amounts
                        paid in advance by the Advertiser and not used, all Campaigns activated through its
                        user account will be stopped immediately. 6.2The Company may offer Advertisers
                        discounts, rebates, or other promotions in respect of the Services offered, in
                        accordance with its commercial policy. Any such discounts or other types of promotions
                        will be displayed on the Website. 6.3The price for the Services provided by the
                        Company is calculated based on the price model of the advertising Campaign type chosen
                        by the Advertiser and statistics gathered by the Company. For details, please refer to
                        the account form that you can find on the Website. 6.4All funded accounts that have
                        been inactive for up to 6 months will lose access to their funds. If the account
                        remains inactive it will be permanently deactivated and the funds will be written off
                        with no refunds. 6.5Referral program 6.6The paragraphs below are aimed to define the
                        process of providing the User with the rights of enjoyment and gaining profit from
                        participation in the referral program. 6.7In terms of referral program the following
                        is explained: Referrer – is the User participating in the referral program and
                        receiving remuneration for engagement of every new User; Referral – a new User,
                        engaged by a Referrer; 6.8Every user is entitled to participate in the referral
                        program. 6.9Each user is provided with a special link indicating its unique ID. All
                        new (unregistered in the system before following the link) Users who used the offered
                        link and went to the website, will automatically be marked with a (referral)
                        cookie-record, which will remain active for 30 days. 6.10Newly registered users of the
                        website are checked whether they have the referral cookie-record. If the new Users
                        were invited by the referral (one of the valid users of Blockchain-ads), they
                        automatically become an indefinite referrer of this User. 6.11The users will always be
                        provided with an access to his referral statistics, which includes: the number of
                        clicks on one’s referral link, the number of registrations and revenue. The user has
                        access to the statistics on the page of the referral program inside his account.
                        6.12The remuneration to the referrals shall be paid either from the earnings of the
                        publishers or from the deposits of the advertisers referred by the party to a referral
                        program. 6.13The user (referrer) receives the remuneration in accordance with the
                        number of new invited users (referrals) and the up-to-date conditions of the referral
                        program. 6.14The increase of the share of the remuneration in the referral program
                        will take effect the next month after the increase of referrals’ earnings. 6.15The
                        Administration reserves the right to change conditions of the terms of use, access,
                        and gain profit from the referral program unilaterally without informing the user. The
                        user can read the current conditions of the referral program on the webpage:
                        blockchain-ads.io/referral. Blockchain-Ads always adds and counts the amount of
                        remuneration from the referral program in accordance with the conditions regarding
                        these matters. 6.16In order to gain referrals, referrers should make sure that the
                        Blockchain-ads website was accessed by the referral through the link, assigned to
                        referral in course of registration. If the link wasn’t used, the referral will be
                        considered as a regular user.{" "}
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">7. Refund Policy </h5>
                    <p className="question-text">
                        7.1ALL AMOUNTS PAID BY ADVERTISERS AND REFLECTED IN USERS’ ACCOUNTS ARE
                        NON-REFUNDABLE. 7.2Advertisers may pause or stop their Campaign(s) at any time,
                        without having to give any explanation, using the dashboard offered. The Campaign(s)
                        can be resumed at a later time. If no changes have been brought to the Campaign(s),
                        then they will be resumed instantly. If there were changes brought to the Campaign(s),
                        they will have to go through the review process. 7.3Advertisers are solely responsible
                        for stopping/pausing their Campaign(s). Otherwise, the Campaign will remain active
                        until all the funds transferred in advance to the Company, the value of which is shown
                        for informative purposes in the User account, are spent. If the amounts paid in
                        advance by an Advertiser were fully used for the payment of the Services provided, all
                        the Campaigns will be suspended/will not receive traffic until the Advertiser
                        transfers new funds to the Company, unless they have been stopped/paused.
                        7.4Advertisers agree that if any kind of malware, exploits, hijacks, or viruses are
                        detected on any of the promoted pages, their user accounts will be blocked and they
                        will not be entitled to a refund of the amounts paid in advance. 7.5If a user account
                        stays inactive for six months, it will be permanently deleted, with no way of
                        retrieving it or the paid and unused amounts, upon expiry of the six months term.
                        Blockchain-ads will email the User with regard to long-term inactivity upon deletion
                        and any other specific changes in conditions of the cooperation with the User.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">8. Representation and Warranties </h5>
                    <p className="question-text">
                        8.1Users represent and warrant that they have full authority and power to enter into
                        this Agreement and perform their obligations hereunder. 8.2Users represent and warrant
                        that they are not using the Blockchain-Ads Platform for any illegal purposes that may
                        violate any applicable laws or rights of any third parties (including intellectual
                        property rights). 8.3Users accept and acknowledge that the Company will not be liable
                        in any way for any damages, losses, costs of any kind, arising from the violation by
                        the Publishers of their obligations to Advertisers or vice versa. 8.4Users accept and
                        acknowledge that the information provided by the Company through its Website does not
                        constitute professional, financial and/or investment advice, nor does any information
                        on the Platform constitute a comprehensive, complete, or correct statement of the
                        matters discussed. By using the Platform and/or the Services, Users agree not to hold
                        the Company liable for any potential damage arising from any decision Users make based
                        on information or other content made available to them through the Website.
                        8.5Advertisers represent and warrant that all materials content provided to the
                        Company as well all promoted material and products comply with all applicable laws and
                        regulations and do not breach any third-party rights. 8.6Advertisers represent and
                        warrant that their servers support the traffic directed to their websites through the
                        Publishers’ websites. Advertisers acknowledge the Company takes no responsibility for
                        the consequences arising in case their servers cannot support the traffic. In such a
                        situation, please contact the Company at the below-mentioned email address. Upon
                        receipt of such e-mail, the Company will have the right to suspend your advertising
                        campaign. All information mentioned will be transmitted to the email address:
                        contact@blockchain-ads.com
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">
                        9. Termination of the Agreement at the initiative of the User/the Company{" "}
                    </h5>
                    <p className="question-text">
                        9.1The User has the right to terminate the Agreement established with the Company
                        under this set of Terms and Conditions at any time, subject to a notice period of 14
                        days. The termination may be accomplished by requesting the Company to delete the user
                        account. 9.2If an Advertiser terminates the Agreement, the amounts paid for the
                        Campaigns requested before the termination date and for which the invoice was issued
                        are not refundable. 9.3The Company reserves the right to terminate this Agreement at
                        any given time, subject to a 24h notification sent via email to the User. In such a
                        case, the User will be entitled to a refund of all the amounts paid in advance and not
                        used in case of termination for reasons not attributable to the User. The amounts owed
                        to the Advertiser is given by the value recorded for informative purposes from time to
                        time in the user account of the Advertiser. 9.4The refund to the Advertiser will be
                        made in the same way as the transfer performed by the User, as follows: if the funds
                        were sent via bank transfer, the refund will be also made via bank transfer; If the
                        User transferred the funds via cryptocurrencies, and their collection by the Company
                        was made in Euro through the payment processor, the User will also receive the funds
                        returned in cryptocurrencies, with the amount refunded in Euro by the Company being
                        converted into cryptocurrencies through the payment processor, using the exchange rate
                        used by the processor.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">10. Privacy and use of cookies </h5>
                    <p className="question-text">
                        10.1Any information you provide during access to and use of the Sites is governed by
                        Blockchain-Ads Privacy Policy. By using the Sites, you agree to the collection, use,
                        and sharing, if any, of your information as set forth in our Privacy Policy. Any
                        dispute over privacy is subject to the T&amp;C and the Privacy Policy. 10.2In
                        addition, we use cookies and other automated means of data collection on our Sites.
                        Please read our Cookie Policy to understand how we use cookies and such other
                        automated means on the Sites.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">11. Limitations of Use </h5>
                    <p className="question-text">
                        11.1By using this website, you warrant on behalf of yourself and other parties you
                        represent that you will not: modify, copy, prepare derivative works of, decompile, or
                        reverse engineer any materials and software contained on this website; remove any
                        copyright or other proprietary notations from any materials and software on this
                        website; transfer the materials to another person or “mirror” the materials on any
                        other server; knowingly or negligently use this website or any of its associated
                        services in a way that abuses or disrupts our networks or any other service
                        BlockchainAds Labs LLC, Singapore provides; use this website or its associated
                        services to transmit or publish any harassing, indecent, obscene, fraudulent, or
                        unlawful material; use this website or its associated services in violation of any
                        applicable laws or regulations; use this website in conjunction with sending
                        unauthorized advertising or spam; harvest, collect, or gather user data without the
                        user’s consent; or use this website or its associated services in such a way that may
                        infringe the privacy, intellectual property rights, or other rights of third parties.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">12. Intellectual Property </h5>
                    <p className="question-text">
                        12.1The intellectual property in the materials contained in this website is owned by
                        or licensed to BlockchainAds Labs LLC, Singapore and is protected by applicable
                        copyright and trademark law. We grant our users permission to download one copy of the
                        materials for personal, non-commercial transitory use. 12.2This constitutes the grant
                        of a license, not a transfer of title. This license shall automatically terminate if
                        you violate any of these restrictions or the Terms and Conditions and may be
                        terminated by BlockchainAds Labs LLC, Singapore at any time.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">13. Limitations </h5>
                    <p className="question-text">
                        13.1In connection with your use of the Sites, you will not, and will not allow any
                        third party to: Link to or use the Sites in connection with any material that
                        contains: Adult content, including nudity, sexual terms and/or images of people in
                        positions or activities that are excessively suggestive or sexual; Obscene,
                        defamatory, libelous, slanderous and/or unlawful content; Content that infringes upon
                        the rights of any third party, including copyright, trademark, privacy, publicity or
                        other personal or proprietary right, or that is deceptive or fraudulent; Inflammatory
                        religious content; Politically religious agendas and/or any known associations with
                        hate, criminal and/or terrorist activities; or Hate speech, whether directed at an
                        individual or a group, and whether based upon the race, disability, sex, creed,
                        national origin, religious affiliation, marital status, sexual orientation, gender
                        identity, or language of such individual or group. Remove, obscure, or change any
                        copyright, trademark, hyperlink, or other proprietary rights notices contained within
                        the Websites; Modify, adapt, disassemble, decompile, translate, reverse engineer, or
                        otherwise attempt to discover the source code or structure, sequence, and organization
                        of the Sites or any content contained therein; Use the Sites in any manner that could
                        damage, disable, overburden, or impair the Sites, Blockchain-ads, the third-party
                        site, website, or application on which the Services are available, or any other person
                        or entity; or Collect any information (including, without limitation, email addresses)
                        about other visitors of the Sites; create or transmit unwanted electronic
                        communications to other visitors of the Sites; or otherwise interfere with such
                        visitors’ enjoyment of the Sites. 13.2Unless otherwise expressly authorized in these
                        T&amp;C or on the Sites, you may not take any action to interfere with the Sites or
                        any other visitor’s use of the Sites. You expressly agree that you will not copy,
                        reproduce, modify, create derivative works from, distribute, publicly display or
                        screen scrape any content from the Sites without our prior written consent. You agree
                        not to bypass any measures we may use to prevent or restrict access to the Sites.
                        13.3You agree not to use the Sites for illegal purposes (including, without
                        limitation, unlawful, harassing, libellous, invasion of another’s privacy, abusive,
                        threatening, or obscene purposes). You agree that you will comply with all laws
                        related to your use of the Sites. 13.4Where appropriate, for example in our contact
                        form, you must use your own identity at all times and you must ensure that all
                        information you provide is accurate and up to date to the best of your knowledge.
                        Unless you have permission to do so (and can prove this), you must not use information
                        about any other person.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">14. Liability </h5>
                    <p className="question-text">
                        14.1Our website and the materials on our website are provided on an ‘as is’ basis. To
                        the extent permitted by law, BlockchainAds Labs LLC, Singapore makes no warranties,
                        expressed or implied, and hereby disclaims and negates all other warranties including,
                        without limitation, implied warranties or conditions of merchantability, fitness for a
                        particular purpose, or non-infringement of intellectual property, or other violation
                        of rights. 14.2In no event shall BlockchainAds Labs LLC, Singapore or its suppliers be
                        liable for any consequential loss suffered or incurred by you or any third party
                        arising from the use or inability to use this website or the materials on this
                        website, even if BlockchainAds Labs LLC, Singapore or an authorized representative has
                        been notified, orally or in writing, of the possibility of such damage. 14.3In the
                        context of this agreement, “consequential loss” includes any consequential loss,
                        indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue,
                        loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of
                        reputation, loss of use and/or loss or corruption of data, whether under statute,
                        contract, equity, tort (including negligence), indemnity, or otherwise. 14.4Because
                        some jurisdictions do not allow limitations on implied warranties, or limitations of
                        liability for consequential or incidental damages, these limitations may not apply to
                        you. 14.5Blockchain-ads EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH
                        THE SITES. IN NO EVENT SHALL Blockchain-ads, ITS PARENT, ITS SUBSIDIARY OR AFFILIATED
                        COMPANIES, OR EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS
                        (COLLECTIVELY, “RELEASED PARTIES”), BE LIABLE TO ANY PERSON OR ENTITY FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, LOSSES COSTS, OR
                        EXPENSES WHATSOEVER, INCLUDING WITHOUT LIMITATION, RESULTING FROM (I) PERSONAL INJURY
                        OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, (II) ANY UNAUTHORIZED ACCESS TO OR USE
                        OF THE SITES, (III) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO, FROM, OR VIA THE
                        SITES, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO
                        OR THROUGH THE SITES BY ANY THIRD PARTY, (V) ANY ERRORS, MISTAKES, INACCURACIES, OR
                        OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
                        ANY USE OF THE SITES, OR (VI) OTHERWISE RESULTING FROM THE USE OF THE SITES.
                        NOTWITHSTANDING THE FOREGOING, IF THE RELEASED PARTIES ARE FOUND TO BE LIABLE TO YOU
                        FOR ANY DAMAGE OR LOSS THAT ARISES OUT OF, OR IS IN ANY WAY CONNECTED WITH, YOUR USE
                        OF THE SITES OR ANY CONTENT CONTAINED THEREIN, RELEASED PARTIES’ LIABILITY SHALL IN NO
                        EVENT EXCEED FIVE US DOLLARS (US $5.00).
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">15. Accuracy of Materials </h5>
                    <p className="question-text">
                        15.1The materials appearing on our website are not comprehensive and are for general
                        information purposes only. BlockchainAds Labs LLC, Singapore does not warrant or make
                        any representations concerning the accuracy, likely results, or reliability of the use
                        of the materials on this website, or otherwise relating to such materials or on any
                        resources linked to this website.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title"> 16. Indemnity </h5>
                    <p className="question-text">
                        {" "}
                        16.1To the fullest extent permissible pursuant to applicable law, you agree that you
                        will defend, indemnify, and hold harmless Blockchain-ads, its parent, subsidiary and
                        affiliated companies, each of the foregoing entities’ respective employees, officers,
                        directors, representatives and agents from any and all claims, demands, causes of
                        action, damages, losses, costs, and expenses in any way arising out of your use of the
                        Sites in violation of these T&amp;C, including without limitation (i) all matters
                        related to your access to and use of any Blockchain-ads online services, including,
                        without limitation, your use of the Sites, (ii) your violation of any provision
                        contained in the T&amp;C ( iii) your violation of any third party right, including
                        without limitation any copyright, intellectual property, or privacy right; (iv) any
                        claims that your use of the Sites caused damage to a third party, and/or (v)
                        violations of any and all applicable laws, rules, or regulations from any
                        jurisdiction.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">17. Links </h5>
                    <p className="question-text">
                        17.1BlockchainAds Labs LLC, Singapore has not reviewed all of the sites linked to its
                        website and is not responsible for the contents of any such linked site. The inclusion
                        of any link does not imply endorsement, approval, or control by BlockchainAds Labs
                        LLC, Singapore of the site. Use of any such linked site is at your own risk and we
                        strongly advise you make your own investigations with respect to the suitability of
                        those sites.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">18. Right to Terminate </h5>
                    <p className="question-text">
                        18.1We may suspend or terminate your right to use our website and terminate these
                        Terms and Conditions immediately upon written notice to you for any breach of these
                        Terms and Conditions.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">19. Severance </h5>
                    <p className="question-text">
                        19.1Any term of these Terms and Conditions which is wholly or partially void or
                        unenforceable is severed to the extent that it is void or unenforceable. The validity
                        of the remainder of these Terms and Conditions is not affected.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">20. Governing Law </h5>
                    <p className="question-text">
                        20.1These Terms and Conditions are governed by and construed in accordance with the
                        laws of Singapore. You irrevocably submit to the exclusive jurisdiction of the courts
                        in that State or location.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">21. Questions</h5>
                    <p className="question-text">
                        21.1Should you have any questions regarding these T&amp;C you may contact us at
                        contact@blockchain-ads.com
                    </p>
                </div>
            </div>
        </div>
    );
}
