import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Carousel, MarketplaceCard, Breadcrumbs } from "../../../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../../context";
import localData from "../../../../localData";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShoppingPanel from "../shopping-panel/ShoppingPanel";

const packagesInShoppingCart = [
    {
        media: "TechTimes",
        format: "Feature",
        monthlyTraffic: 705100,
        turnaroundTime: "1-2 weeks",
        price: "5000",
        cover: localData.images.productExample,
    },
    {
        media: "TechTimes",
        format: "Feature",
        monthlyTraffic: 705100,
        turnaroundTime: "1-2 weeks",
        price: "5000",
        cover: localData.images.productExample,
    },
    {
        media: "TechTimes",
        format: "Feature",
        monthlyTraffic: 705100,
        turnaroundTime: "1-2 weeks",
        price: "5000",
        cover: localData.images.productExample,
    },
    {
        media: "TechTimes",
        format: "Feature",
        monthlyTraffic: 705100,
        turnaroundTime: "1-2 weeks",
        price: "5000",
        cover: localData.images.productExample,
    },
    {
        media: "TechTimes",
        format: "Feature",
        monthlyTraffic: 705100,
        turnaroundTime: "1-2 weeks",
        price: "5000",
        cover: localData.images.productExample,
    },
    {
        media: "TechTimes",
        format: "Feature",
        monthlyTraffic: 705100,
        turnaroundTime: "1-2 weeks",
        price: "5000",
        cover: localData.images.productExample,
    },
    {
        media: "TechTimes",
        format: "Feature",
        monthlyTraffic: 705100,
        turnaroundTime: "1-2 weeks",
        price: "5000",
        cover: localData.images.productExample,
    },
];

export default function ShoppingCart() {
    useEffect(() => {
        document.title = "Marketplace";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content = "Markeplace description";
    }, []);
    const { fade } = useGlobalContext().animations;

    const { marketplaceState } = useGlobalContext();
    const {
        publications,
        setPublications,
        MarketplaceCardDetails,
        displayMarketplaceDetailsModal,
        setDisplayMarketplaceDetailsModal,
    } = marketplaceState;

    const { edit, question, password, support, userShield, heart, shoppingCart, trashV2 } = localData.svgs;
    const { productExample } = localData.images;
    const { EmptyCartIcon } = localData.svgs;

    const navigate = useNavigate();

    const handleSubmit = () => {};

    const [packages, setPackages] = useState([]);

    useEffect(() => {
        let tempPackages = [...publications];
        tempPackages = tempPackages.filter((item) => item.isInWishlist && { ...item });
        setPackages(tempPackages);
    }, [publications]);

    const hadleRemoveAllFromShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) => ({ ...item, isInWishlist: false }));
        setPublications(tempPublications);
    };
    const hadleMoveAllToShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.isInWishlist ? { ...item, isInWishlist: false, isInShoppingCart: true } : { ...item }
        );
        setPublications(tempPublications);
    };

    return (
        <motion.main {...fade} className="main wishlist-page">
            {/* <Navbar title="Wishlist" /> */}

            <ShoppingPanel />

            <Breadcrumbs items={[{ name: "PR", href: "/additional-products/pr" }, { name: "wishlist" }]} />
            <br />

            <h2 className="display-2" id="pr-tutorial-47">Wishlist</h2>
            <div className="wishlist-header">
                <div className="wishlist-items-count">{packages.length} items</div>
                {packages.length ? (
                    <div className="setup-btns">
                        <Button
                            startIcon={shoppingCart}
                            name="Move all to cart"
                            color="success"
                            variant="contained"
                            className="setup-btn"
                            onClick={hadleMoveAllToShoppingCart}
                        />

                        <Button
                            className="setup-btn"
                            startIcon={trashV2}
                            name="Delete all"
                            color="danger"
                            onClick={hadleRemoveAllFromShoppingCart}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <br />
            <hr className="hr-divider" />
            <br />
            <br />

            <div className="row">
                {!packages.length ? (
                    <div className="empty-cart-icon">
                        <EmptyCartIcon />
                    </div>
                ) : (
                    <div className="col col-left">
                        {packages.map((item, index) => {
                            return (
                                <MarketplaceCard
                                    key={index}
                                    {...item}
                                    color="primary"
                                    icons={{ favorite: false, shop: false, removeWishlist: true }}
                                />
                            );
                        })}
                        <Modal
                            Toggler={() => {}}
                            Child={MarketplaceCardDetails}
                            display={displayMarketplaceDetailsModal}
                            setDisplay={setDisplayMarketplaceDetailsModal}
                            className="modal-dialog-centered  marketplace-details-modal"
                        />
                    </div>
                )}
            </div>
        </motion.main>
    );
}
