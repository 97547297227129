import React from "react";
import { Button, OptionCard } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import { useNavigate } from "react-router-dom";

export default function CampaignObjectiveModal({ setIsOpen, setShowCampaignBuildOptionModal }) {
    const {
        errorAlert,
        requestedData,
        formatNumber,
        isDarkModeEnabled,
        campaignState,
        setShowCampaignWizardDrawer,
        setCampaignWizardStatus,
        defaultCampaign,
        setCreatives,
        setCampaignState,
        requestedData: {
            userProfileData: {
                currentOrganization: { balance, PredictedBalance },
            },
        },
    } = useGlobalContext();

    const { attachment, contentViews, growth, megaphone, publicRelation, register } = localData.images;

    const { close, wallet, budget } = localData.svgs;

    const closeModal = () => {
        setIsOpen(false);
    };

    const navigate = useNavigate()

    const handleSubmit = (type) => {
        setCampaignState(defaultCampaign);
        setCreatives([])
        closeModal();
        setCampaignWizardStatus("create")
        navigate("/campaigns/create")
        // setShowCampaignWizardDrawer("block");
    };

    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">Choose a campaign objective</h2>
                <p className="modal-description">We’ll make recommendations based on your selection</p>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                <div className="col col-left">
                    <h4 className="col-title">Awareness</h4>
                    <OptionCard
                        onClick={() => handleSubmit("Brand Awareness")}
                        icon={megaphone}
                        title="Brand Awareness"
                        description="Reach the right people with your brand"
                    />
                </div>
                <div className="col col-middle">
                    <h4 className="col-title">Consideration</h4>
                    <OptionCard
                        onClick={() => handleSubmit("Website Visits")}
                        icon={contentViews}
                        title="Website Visits"
                        description="Get more visits to your landing page"
                    />
                    <OptionCard
                        onClick={() => handleSubmit("Engagement")}
                        icon={publicRelation}
                        title="Engagement"
                        description="Increase website engagement and actions "
                    />
                </div>
                <div className="col col-right">
                    <h4 className="col-title">Conversion</h4>
                    <OptionCard
                        onClick={() => handleSubmit("Sign Ups")}
                        icon={register}
                        title="Sign Ups"
                        description="Gather info from people interested in your offer"
                    />
                    <OptionCard
                        onClick={() => handleSubmit("Downloads")}
                        icon={attachment}
                        title="Downloads"
                        description="Drive more users to download your app/dApp"
                    />
                    <OptionCard
                        onClick={() => handleSubmit("Sales/Deposits")}
                        icon={growth}
                        title="Sales/Deposits"
                        description="Drive more deposits/transactions/sales"
                    />
                </div>
            </div>
        </>
    );
}
