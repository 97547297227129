import React, { useState, useEffect, useRef } from "react";
import localData from "../../localData";
import { Button, Checkbox } from "..";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import useFormatter from "../../hooks/useFormatter";

export default function MultiSelect({
    label,
    labelInfo,
    items = [],
    setItems = () => console.log('Error: "setItems function required"'),
    placeholder = "Select Tags",
    className = "",
    variant = "outlined",
    color,
    toggleIcon,
    children,
    limitTags,
    placeholderClassName = "",
    callback = () => {},
    name = "",
    direction = "down",
    withCheckbox = true,
    selectedItemsLimit = null,
    useAsButton = false,
    buttonCallback = () => {},
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);

    const clickWrapper = useRef(null);
    const selectRef = useRef(null);

    const { arrowDown, close } = localData.svgs;

    const {addCommas} = useFormatter()

    useEffect(() => {
        if(!clickWrapper.current) return
        let handler = (e) => !clickWrapper.current?.contains(e.target) && setIsOpen(false);
        document.addEventListener("click", handler);
        return () => document.removeEventListener("click", handler);
    });

    const openMenu = () => {
        setIsOpen(true);

        // setTimeout(() => scrollIntoView(), 100);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const setSelected = (id) => {
        let tempItems = [...items];
        tempItems = tempItems.map((item) => (item.id === id ? { ...item, isSelected: true } : { ...item }));
        setItems(tempItems);
        // setSelectedOption(tempItems.findIndex((item) => item.isSelected === true));
    };
    const removeSelected = (id) => {
        let tempItems = [...items];
        tempItems = tempItems.map((item) => (item.id === id ? { ...item, isSelected: false } : { ...item }));
        setItems(tempItems);
        // setSelectedOption(tempItems.findIndex((item) => item.isSelected === true));
    };

    const [countSelectedItems, setCountSelectedItems] = useState(null);

    const getCountSelectedItems = (items) => {
        return items.reduce((count, item) => count + (item.isSelected ? 1 : 0), 0);
    };

    useEffect(() => {
        callback(items, name);

        const isAllSelected = items.find((item) => !item.isSelected) ? false : true;
        setIsChecked(isAllSelected);
        setCountSelectedItems(getCountSelectedItems(items));
    }, [items]);

    // const scrollIntoView = () => {
    //     const activeItem = selectedOptionRef.current;
    //     activeItem?.scrollIntoView({
    //         // behavior: 'smooth',
    //         block: "nearest",
    //         inline: "nearest",
    //     });
    // };

    // const handleKeyDown = (e) => {
    //     if (["ArrowUp", "ArrowDown"].includes(e.key)) e.preventDefault(); // to prevent autocompleteField default behaviour when pressed ArrowUp or ArrowDown
    //     if (e.key === "Tab") closeMenu();
    //     if (["ArrowUp", "ArrowDown"].includes(e.key) && !isOpen) {
    //         openMenu();
    //         return;
    //     }
    //     switch (e.key) {
    //         case "ArrowDown":
    //             if (selectedOption >= items.length - 1) return;
    //             setSelectedOption((prevIndex) => prevIndex + 1);
    //             break;
    //         case "ArrowUp":
    //             if (selectedOption <= 0) return;
    //             setSelectedOption((prevIndex) => prevIndex - 1);
    //             break;
    //         case "Enter":
    //             selectedOptionRef.current?.click();
    //             isOpen && setTimeout(()=>closeMenu(),0)
    //             break;
    //     }
    // };

    // useEffect(() => {
    //     scrollIntoView();
    // }, [selectedOption]);

    const getPlaceholder = () => {
        return (
            <div className="wrapper placeholder">
                <span className={`multi-select-toggle-title ${placeholderClassName}`}>{placeholder}</span>
            </div>
        );
    };

    const getSelectedItems = () => {
        const selectedItems = items.filter((item) => item.isSelected && { ...item });

        if (!selectedItems.length) return getPlaceholder();

        return (
            <div className="wrapper">
                {selectedItems.map(({ startIcon, endIcon, title, id }, index) => {
                    if (index >= limitTags) return;
                    return (
                        <div className="multi-select-tag" key={index} onClick={(e) => e.stopPropagation()}>
                            {!useAsButton && (
                                <span className="multi-select-tag-close" onClick={() => removeSelected(id)}>
                                    {close}
                                </span>
                            )}
                            {startIcon && <span className="startIcon">{startIcon}</span>}
                            <span className={`multi-select-tag-title`}>{title}</span>
                            {endIcon && <span className="endIcon">{endIcon}</span>}
                        </div>
                    );
                })}
                {selectedItems.length > limitTags && (
                    <>
                        <span
                            className="rest"
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                selectedItems.map((item, index) => {
                                    if (index < 2) return "";
                                    return <span className="multiselect-label-hidden">{item.title} </span>;
                                })
                            )}
                            data-tooltip-id={`tooltip-campaign-rest-${name}`}
                            data-tooltip-place="top"
                        >
                            +{selectedItems.length - limitTags} items
                        </span>
                        <Tooltip
                            effect="scale"
                            id={`tooltip-campaign-rest-${name}`}
                            className="custom-tooltip multiselect-tooltip"
                        />
                    </>
                )}
            </div>
        );
    };

    // don't render menu while it closed (replace "isOpen" variable with "isAnimate" from select classname and remove commented isOpen scopes around menu)
    // const [isAnimate, setIsAnimate] = useState(false);

    // useEffect(() => {
    //     setIsAnimate(isOpen);
    // }, [isOpen]);
    //

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckbox = (isChecked) => {
        let tempItems = [...items];
        tempItems = tempItems.map((item) => ({ ...item, isSelected: isChecked ? true : false }));
        setItems(tempItems);
    };

    return (
        <div
            className={`multi-select ${isLoading ? "multi-select-loading" : ""} ${
                isOpen && !useAsButton ? "active" : ""
            }  ${className} direction-${direction}`}
            ref={selectRef}
        >
            {label && (
                <label
                    className="multi-select-label"
                    data-tooltip-id={`tooltip-campaign-${name}`}
                    data-tooltip-content={labelInfo}
                >
                    {label}
                </label>
            )}
            {labelInfo && <Tooltip id={`tooltip-campaign-${name}`} className="custom-tooltip" />}

            <div className="multi-select-toggle-wrapper" ref={clickWrapper}>
                <Button
                    className={`multi-select-toggle`}
                    data-toggle="select"
                    onClick={() => {
                        if (useAsButton) return buttonCallback();
                        isOpen ? closeMenu() : openMenu();
                    }}
                    variant={variant}
                    color={color}
                    // onKeyDown={handleKeyDown}
                >
                    {!items.length ? getPlaceholder() : getSelectedItems()}
                    <span
                        className={`endIcon multi-select-toggle-icon`}
                        // onClick={() => (isOpen ? closeMenu() : openMenu())}
                    >
                        {toggleIcon || arrowDown}
                    </span>
                </Button>
            </div>

            {/* {isOpen && ( // don't render menu while it closed */}
            {useAsButton ? (
                ""
            ) : (
                <div
                    className={`multi-select-menu ${
                        selectedItemsLimit && countSelectedItems > selectedItemsLimit - 1
                            ? "limit-reached"
                            : ""
                    }`}
                    onClick={(e) => e.stopPropagation()}
                    data-tooltip-id={`tooltip-campaign-menu-${name}`}
                    data-tooltip-content={"Only two options allowed"}
                >
                    {withCheckbox && (
                        <>
                            <Checkbox
                                callback={handleCheckbox}
                                checked={isChecked}
                                color="secondary"
                                size="sm"
                                className={`multi-select-item`}
                                label={`${isChecked ? "Remove All" : "Select All"}`}
                            />
                            <hr style={{ borderColor: "rgba(0,0,0,0.1)", borderTop: 0 }} />
                        </>
                    )}
                    {!items.length || !items.find((item) => !item.isSelected) ? (
                        <div className="multi-select-item disabled">empty.</div>
                    ) : (
                        items.map(
                            ({ isSelected, title, startIcon, endIcon, id, size,percentage }, index) =>
                                !isSelected && (
                                    <div
                                        key={index}
                                        id={`multi-select-item-${id}`}
                                        className={`multi-select-item`}
                                        onClick={() => setSelected(id)}
                                    >
                                        {startIcon && <span className="startIcon">{startIcon}</span>}

                                        <span className="multi-select-item-title">{title}</span>
                                        {endIcon && <span className="endIcon">{endIcon}</span>}
                                        {/* {size && <span className="multi-select-item-size">{addCommas(size)}</span>} */}
                                        {/* {percentage && <span className="multi-select-item-size">{addCommas(percentage)}%</span>} */}
                                    </div>
                                )
                        )
                    )}
                    {children}
                </div>
            )}
            {/* )} */}
            {selectedItemsLimit && countSelectedItems > selectedItemsLimit - 1 && (
                <Tooltip id={`tooltip-campaign-menu-${name}`} className="custom-tooltip" />
            )}
        </div>
    );
}
