import React, { useState } from "react";
import { Container, TextField, Button, Box, Typography, Paper, CircularProgress } from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import { useGlobalContext } from "../../../context";

const AdminAccountCreation = () => {
  const { successAlert, errorAlert } = useGlobalContext();
  const [accountData, setAccountData] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(false);

  const { createUserFromCalendlyData } = useFetch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading state
  
    try {
      const data = await createUserFromCalendlyData(() => {}, accountData);
      console.log(data, "data");
      
      successAlert(data.msg); // Show success message
      setAccountData({ email: "" }); // Reset form after success
    } catch (err) {
      let errorMessage = err?.response?.data?.msg; 
      if (!errorMessage) {
        errorMessage = "An unexpected error occurred."; 
      }
  
      if (err?.response?.status === 404) {
        errorMessage = "No Calendly event found for this email.";
      }
  
      errorAlert(errorMessage); 
      console.error(err, "=createNewAccount= request error");
    } finally {
      setIsLoading(false); 
    }
  };
  

  const handleInputChange = (event) => {
    setAccountData({ ...accountData, email: event.target.value });
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: "7%",
        p: 0,
      }}
    >
      <Paper
        sx={{
          padding: 4,
          borderRadius: "16px",
          textAlign: "center",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#1f8352" }}
        >
          Create Account
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: 3, color: "#757575" }}
        >
          Enter email to create your account.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Email"
            value={accountData.email}
            onChange={handleInputChange}
            sx={{
              marginBottom: 3,
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
            required
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isLoading} // Disable button while loading
            sx={{
              backgroundColor: isLoading ? "#b0bec5" : "#1f8352",
              color: "#fff",
              textTransform: "none",
              borderRadius: "8px",
              paddingX: 3,
              paddingY: 1,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: isLoading ? "#b0bec5" : "#145ca8",
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : "Create Account"}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default AdminAccountCreation;
