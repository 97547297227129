import { useEffect, useState } from "react";
import axios from "axios";
import { Calendar, Loading } from "../../../components";
import { useGlobalContext } from "../../../context";
import { default as ReactSelect } from "react-select";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import useFetch from "../../../hooks/useFetch";

const endDate = new Date(); // Today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
    startDate,
    endDate,
    key: "selection",
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: "rgba(255,255,255,.5)",
        outline: "none",
        backgroundColor: "transparent",
        color: "black",
        fontWeight: "400",
        "&:hover": {
            borderColor: "rgba(255,255,255,.8)",
        },
        width: "100%",
        minWidth: "200px",
        maxWidth: "300px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
        color: state.data.color,
        padding: "7px 15px",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: !state.isSelected && "rgba(0,0,0,0.05)",
            color: state.data.color || "black",
        },
    }),
    menuList: (provided) => ({
        ...provided,
        padding: "0px",
        overflowY: "auto",
        scrollbarWidth: "thin",
        borderTopLeftRadius: "5px",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.data.color,
    }),
};

function formatTimestamp({ _seconds, _nanoseconds }) {
    const date = new Date(_seconds * 1000 + _nanoseconds / 1e6);
    return date.toLocaleString();
}

export default function ReportsPage() {
    const {getCampaignLogs} = useFetch()
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [logsData, setLogsData] = useState([])
    
    const [selectedItem, setSelectedItem] = useState(null);
    const [timeRange, setTimeRange] = useState(SELECTIONRANGE);
    const [logsInformation, setLogsInformation] = useState({
        organizationID: null,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
    });
    const [isLoading, setIsLoading] = useState(false);

    const {
        successAlert,
        errorAlert,
        requestedData: { userProfileData },
    } = useGlobalContext();

    function formatDate(value = null) {
        const date = value !== null ? new Date(value) : new Date();
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleCalendar = (range) => {
        const { startDate, endDate } = range;
        setTimeRange(range);
        setLogsInformation((prev) => ({
            ...prev,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
        }));
    };

    const fetchCampaignLogs = async () => {
        setIsLoading(true);

        try {
            const data = await getCampaignLogs(
                () => {},
                logsInformation
              );              
            setLogsData(data.res_data)
            successAlert(data.res_msg || "Data fetched successfully!");
        } catch (error) {
            const errorMessage =
                error?.response?.data?.msg || "An unexpected error occurred.";
            if (error?.response?.status === 404) {
                errorAlert("No Logs event found");
            } else {
                errorAlert(errorMessage);
            }
            console.error("Error fetching campaign logs:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterAllOrganization = () => {
        const array = userProfileData.allOrganization.map((item) => ({
            title: item.name || "---Not Exist---",
            isSelected: item.id === userProfileData.currentOrganization.id,
            id: item.id,
            adminID: item.adminID,
            color:
                item.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
                    ? "rgb(150, 45, 255)"
                    : item.adminID === userProfileData.userId
                    ? "rgb(42, 171, 126)"
                    : "",
            value: item.id,
            label: item.name || "---Not Exist---",
        }));

        array.sort((a, b) => a.title.localeCompare(b.title));

        setItems(array);
        setFilteredItems(array);

        const defaultItem = array.find(
            (item) => item.id === userProfileData.currentOrganization.id
        );
        setSelectedItem(defaultItem || null);
    };

    const handleChange = (selectedOption) => {
        setSelectedItem(selectedOption);
        setLogsInformation((prev) => ({
            ...prev,
            organizationID: selectedOption?.id || null,
        }));
    };

    useEffect(() => {
        if (!userProfileData.allOrganization.length) return;
        filterAllOrganization();
    }, [userProfileData]);

    useEffect(() => {
        if (logsInformation.organizationID) {
            fetchCampaignLogs();
        }
    }, [logsInformation]);

    useEffect(() => {
        setLogsData([])
    }, [])

    return (
        <Grid>
        <Grid container sx={{background: "#ffff", borderRadius: "8px", maxWidth: "600px", alignItems: "center", padding: "10px"}}>
            <Calendar
                className="reports-calendar"
                _selectionRange={timeRange}
                callback={handleCalendar}
            />
            <ReactSelect
                id="login-tutorial-8"
                value={selectedItem}
                options={filteredItems}
                className="react-select accounts-autocomplete"
                onChange={handleChange}
                placeholder="Select Account"
                menuPlacement="top"
                styles={customStyles}
            />
            
        </Grid>
        <br />
        <br />
        <br />

        {isLoading ? <Loading /> : (!logsData.length ? <h2>No Logs Found</h2> : <Grid container spacing={3}>
            {logsData.map((log, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Campaign: {log.campaignName}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Operation:</strong> {log.operation}
                            </Typography>
                            <Typography variant="body1">
                                <strong>User:</strong> {log.userName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <strong>User ID:</strong> {log.userId}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Timestamp:</strong> {formatTimestamp(log.timestamp)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <strong>Organization ID:</strong> {log.organizationId}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>)}
        </Grid>
    );
}
