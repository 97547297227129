import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? 0 : 90;
let showYaxis = isSmallScreen ? true : false;

export default function Example({
    data = [],
    defaultData = [],
    isLoading = false,
    lines = [],
    className = "",
}) {
    const { formatNumber, formatCurrency, addCommas } = useFormatter();
    const CustomTooltip = ({ payload, label }) => {
        if (!payload || payload.length === 0) return null;
        const originalData = payload[0].payload;
        const ySecondaryName = originalData.ySecondaryName || "";

        return (
            <div className="chart-tooltip">
                <strong className="label">{` ${label}`}</strong>
                {ySecondaryName === "cost" && (
                <p className="label">{`Avg. Conversion Cost: $${parseFloat(originalData.progress).toFixed(2)}`}</p>
                )}
                {ySecondaryName == "impressions" && (
                    <p className="label">{`Impressions: ${formatNumber(originalData.progress)}`}</p>
                )}
                {ySecondaryName == "clicks" && (
                    <p className="label">{`Clicks: ${formatNumber(originalData.progress)}`}</p>
                )}
                {ySecondaryName == "conversions" && (
                    <p className="label">{`Conversions: ${formatNumber(originalData.progress)}`}</p>
                )}
                {ySecondaryName == "volumes" && (
                    <p className="label">{`Volumes: ${formatCurrency(originalData.progress,0)}`}</p>
                )}
            </div>
        );
    };

    return (
        <div className={`position ${className} area-chart`}>
            <div className="position-inner">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        // width={500}
                        // height={400}
                        data={data}
                        margin={{
                            // top: 10,
                            // right: 30,
                            left: isSmallScreen ? -20 : 0,
                            // bottom: 0,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#00B368" stopOpacity={1} />
                                <stop offset="100%" stopColor="rgba(49, 233, 156, 0.16)" stopOpacity={0.16} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis
                            dy={20}
                            dataKey="name"
                            tick={{ fontSize: fontSize }}
                            tickLine={false}
                            axisLine={false}
                        />
                        <YAxis
                            tickFormatter={(tick) =>
                                (data[0].ySecondaryName === "volumes" ? "$" : "") + (addCommas(tick) || "0")
                            }
                            tick={{ fontSize: fontSize }}
                            tickLine={false}
                            axisLine={false}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                            type="monotone"
                            dataKey="progress"
                            strokeWidth={2}
                            stroke="rgb(42, 171, 126)"
                            fill="url(#colorUv)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
