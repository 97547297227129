import React from "react";
import localData from "../../../localData";

export default function AverageCostCard ({ title = "", image = "", from = "", to = "",}) {

    return (
        <div
            className="card chart-card average-cost-card"
        >   
            <img src={image}/>
            <h4 className="card-title">{title}</h4>
            <p className="card-price">{from} - {to}</p>
        </div>
    );
}
