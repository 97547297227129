import React from "react";
import localData from "../../../localData";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const { notFound } = localData.images;

export default function OptionCard({
    title = "title",
    description = "lorem ipsum dolor samet",
    icon = notFound,
    selected = false,
    onClick = () => {},
    borderColor = "#ccc",
}) {
    return (
        <div
            className={`card option-card  bg-white ${selected ? "active-option-card" : ""}`}
            onClick={onClick}
            style={{ borderColor: borderColor }}
        >
            {selected && (
                    <div className="checkbox-container">
                       <CheckCircleIcon />
                   </div>
            )}
            <div className="card-icon">
                <img src={icon} alt="" />
            </div>
            <div className="wrapper">
                <h4 className="card-title">{title}</h4>
                <p className="card-description">{description}</p>
            </div>
        </div>
    );
}
