import React, { useState, useEffect, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Field } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import { auth } from "../../../config/firebase"

export default function ResetPassword({}) {
    const { getUpdateUserInfo,successAlert, isLoading, errorAlert ,  handleSignOut} = useGlobalContext();
    const { preloaderBig } = localData.images;

    const [state, setState] = useState({
        current_password: "",
        new_password: "",
    });

    const resetState = () =>{
        setState({
            current_password: "",
            new_password: "",
        })
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // if (state.current_password !== state.new_password) return errorAlert("Passwords don't match");
        getUpdateUserInfo({ password: state.new_password }, () => {
            // resetState();
            handleSignOut('Password updated. Re-login for security.')
        });
    };

    return (
        <div className="reset-password">
            <form className="form" onSubmit={handleSubmit}>
                <div className="form-header">
                    <h2 className="form-title">Reset Password</h2>
                </div>

                <Field
                    name="current_password"
                    value={state.current_password}
                    type="text"
                    label="Current Password"
                    color="secondary"
                    placeholder="******"
                    required={true}
                    size="sm"
                    callback={handleOnChange}
                />
                <Field
                    name="new_password"
                    value={state.new_password}
                    type="text"
                    label="New Password"
                    color="secondary"
                    placeholder="******"
                    required={true}
                    size="sm"
                    callback={handleOnChange}
                />

                <Button
                    className="submit-btn"
                    name="Submit"
                    color="primary"
                    variant="contained"
                    size="sm"
                    disabled={
                        state.current_password === "" ||
                        state.new_password === "" ||
                        // state.current_password !== state.new_password ||
                        isLoading.passwordIsUpdating
                    }
                    icon={
                        isLoading.passwordIsUpdating ? (
                            <span className="endIcon">
                                <img src={preloaderBig} />
                            </span>
                        ) : null
                    }
                />
            </form>
        </div>
    );
}
