import React from "react";
import localData from "../../../localData";
import useFormatter from "../../../hooks/useFormatter";

export default function ChartCard({ title = "", value = "", percentage = "", color = "", text = "" }) {
    const { chartVectorIcon, chartArrowUpIcon, chartArrowDownIcon } = localData.svgs;
    const { addCommas } = useFormatter();

    return (
        <div
            className={`card chart-card ${
                percentage == 0 ? "card-warning" : percentage < 0 ? "card-danger" : ""
            }`}
        >   
            <div className="" style={{display: "flex", justifyContent: "space-between"}}>
            <h4 className="card-title">{title}</h4>
            </div>
          
            <div className="card-body">
                <div className="card-value">{value}</div>
                {/* <div className="card-cover">{chartVectorIcon}</div> */}
            </div>
            <div className="card-footer">
                <div className="card-value">
                    <div className={`card-value-icon ${percentage > 0 ? "bg-success" : "bg-danger"}`}>
                        {percentage > 0 ? chartArrowUpIcon : chartArrowDownIcon}
                    </div>
                    <div className="card-value-percentage">{percentage}%</div>
                    <p className="card-value-text">{text}</p>
                </div>
            </div>
        </div>
    );
}
